//COMPONENTS
import { CartLineItem } from "./Components/CartLineItem/CartLineItem.component";
import { CartPromoBox } from "./Components/CartPromoBox/CartPromoBox.component";

//CONTROLS
import { CartCheckOut } from "./Controls/CartCheckOut";
import { CartCheckoutOptions } from "./Controls/CartCheckoutOptions";
import CartItemList from "./Controls/CartItemList";
import { OrderItemSkuConfigurations } from "./Controls/OrderItemSkuConfigurations";

//CUSTOM

export namespace CartModule {
  export const CoreComponents = {
    CartLineItem,
    CartPromoBox,
  };

  export const CoreControl = {
    CartCheckOut,
    CartCheckoutOptions,
    CartItemList,
    OrderItemSkuConfigurations,
  };
  export const Custom = {};
}

import { SearchSku } from "../../../../interface/SearchProduct";
import { ProductConfigurator, Sku } from "../../../../interface/Product";
import { validateProductInStock } from "../../../Product/Components/ProductOutOfStock/ProductOutOfStock.componentService";

export interface ProductCardActionsProps {
  cardConfiguration: any;
  sku: Sku | SearchSku;
  productConfigurator?: ProductConfigurator;
  type: "listing" | "dropdown";
}

export class ProductCardActionsModel {
  type: ProductCardActionsProps["type"] = "listing";
  sku!: Sku | SearchSku;
  productConfigurator?: ProductConfigurator;
  cardConfiguration: any = {};
  isQtyFieldRequired: boolean = false;
  buttonStack: ProductActionButton[] = [];
  isFetching: boolean = true;
  itemCount: number = 1;
  showProductModifierModal: boolean = false;

  constructor({
    props: { type, sku, cardConfiguration, productConfigurator },
    sitePurpose,
    isAuthenticatedUser,
    isFetching,
    itemCount,
    showProductModifierModal,
  }: {
    props: ProductCardActionsProps;
    sitePurpose: string;
    isAuthenticatedUser: boolean;
    isFetching: boolean;
    itemCount: number;
    showProductModifierModal: boolean;
  }) {
    this.type = type;
    this.sku = sku;
    this.productConfigurator = productConfigurator;
    this.cardConfiguration = cardConfiguration;
    this.isFetching = isFetching;
    this.itemCount = itemCount;
    this.showProductModifierModal = showProductModifierModal;

    this.setButtonStack(isAuthenticatedUser, sitePurpose);
    this.checkForQtyFieldRequired();
  }

  private setButtonStack(isAuthenticatedUser: boolean, sitePurpose: string) {
    this.buttonStack = [];
    const skuSettings = this.sku.settings ?? {};
    const isProductInStock = validateProductInStock(this.sku);
    const hasProductPrice = this.sku.listPrice;
    this.cardConfiguration?.buttons?.forEach((props: any) => {
      let { type = "VIEW", listingButtonDisplayCriteria } = props;

      let displayCriteriaPassed = true;
      switch (listingButtonDisplayCriteria) {
        //Add strapi button config display criteria here
        default:
          displayCriteriaPassed = true;
      }

      let productCriteriaPassed = true;
      switch (type) {
        case "ADD_TO_CART":
          if (skuSettings.multiVariant || sitePurpose === "Non-Transactional") return; //dont show this button if it is a multi-variant product
          if (skuSettings.skuAllowAddToCartFlag && hasProductPrice && isProductInStock) {
            productCriteriaPassed = true;
          } //Check if everything required for an add to cart action
          if (skuSettings.skuRequireLoginToAddToCart && !isAuthenticatedUser) {
            props.shouldDisplayAuthenticationRequiredMessageFlag = true;
          }
          break;
        case "ADD_TO_QUOTE":
          if (skuSettings.options || sitePurpose === "Non-Transactional") return; //dont show this button if it is a multi-variant product
          if (skuSettings.skuAllowQuoteFlag) {
            productCriteriaPassed = true;
          }
          break;
        default:
          productCriteriaPassed = true;
      }

      if (displayCriteriaPassed && productCriteriaPassed) {
        this.buttonStack.push(props);
      }
    });
  }
  private checkForQtyFieldRequired() {
    this.isQtyFieldRequired = this.buttonStack.some(
      (button: ProductActionButton) => button.type === "ADD_TO_CART" || button.type === "ADD_TO_QUOTE",
    );
  }
}

interface ProductActionButton {
  type: ProductActionButton_actionType;
  listingButtonLabel: string;
}

type ProductActionButton_actionType = "ADD_TO_CART" | "ADD_TO_QUOTE" | "VIEW";

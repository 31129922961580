import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useState } from "react";
import { addToCartOrQuote } from "../../../actions";
import { transformShipping, transformPickup, useFormatCurrency } from "../../../hooks";
import { getProductRoute } from "../../../selectors";

import { isAuthenticated } from "../../../utils";
import { axios, getSdkURL } from "../../../services";
import { useElementContext } from "../../../contexts";

const OrderItem = ({
  quantity,
  sku_skuID,
  sku_product_productName,
  sku_product_urlTitle,
  images,
  BrandName,
  isSeries,
  ProductSeries,
  calculatedExtendedPriceAfterDiscount,
  calculatedExtendedUnitPriceAfterDiscount,
  sku_calculatedSkuDefinition,
  sku_imageFile,
  price,
  sku_product_productID,
  orderItemID,
  files,
  orderChildItems,
  showActions = true,
  skuConfigurations = [],
}) => {
  const {
    CartModule,
    ProductModule,
    CommonModule: { SimpleImage, Modal, AddReview },
  } = useElementContext();
  const [formatCurrency] = useFormatCurrency({});
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const childBundleItems = orderChildItems?.filter((item) => item?.parentOrderItem_orderitemID === orderItemID);

  const productRouting = useSelector(getProductRoute);
  const [showModal, setModal] = useState(false);
  const [selectedRate, setSelectedRate] = useState(0);
  const [hoveredRate, setHoveredRate] = useState(0);

  return (
    <>
      <div className="row border-bottom py-3 d-none d-lg-flex">
        <div className="col-sm-2 col-3">
          <Link to={`/${productRouting}/${sku_product_urlTitle}?skuid=${sku_skuID}`} className="p-2 mx-auto mr-sm-4">
            {images && images?.length > 0 && (
              <SimpleImage
                className="border img-fluid m-auto image_container productImage"
                src={images?.at(0)}
                alt={sku_product_productName}
                type="product"
              />
            )}
          </Link>
        </div>
        <div className="col-sm-4 col-9">
          {isSeries && <span className="product-meta d-block font-size-xs pb-1">{ProductSeries}</span>}
          {/* <!--- only show this span if part of a bundled product? ---> */}
          <h3 className="product-title font-size-base mb-2 h5 p-0">
            <Link className="link" to={`/${productRouting}/${sku_product_urlTitle}?skuid=${sku_skuID}`}>
              {sku_product_productName}
            </Link>
          </h3>
          <CartModule.CoreControl.OrderItemSkuConfigurations skuConfigurations={skuConfigurations} />
          {/* <!--- product title ---> */}
          <div className="font-size-sm">
            {BrandName} <span className="text-muted mr-2">{sku_calculatedSkuDefinition}</span>
          </div>
          {/* <!--- brand / sku ---> */}
          {files && files.length > 0 && isAuthenticated() && (
            <div>
              {files
                .filter((file) => file.baseID === sku_product_productID)
                .map((file) => (
                  <span
                    className="link-primary pe-auto text-decoration-underline"
                    key={file.file_fileID}
                    onClick={(e) => {
                      e.preventDefault();

                      axios({
                        method: "POST",
                        url: `${getSdkURL()}api/scope/downloadFile?fileID=${file.file_fileID}`,
                        responseType: "blob", // had to add this one here
                      }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `${file.file_fileName}.${file.file_fileType}`);
                        document.body.appendChild(link);
                        link.click();
                      });
                    }}
                  >
                    {t("frontend.cart.download")}
                  </span>
                ))}
            </div>
          )}
        </div>
        <div className="col-sm-12 col-md-6 d-none d-sm-block">
          <div className="row">
            <div className="col-sm-3">
              <ProductModule.CoreComponents.ProductPrice
                salePrice={calculatedExtendedUnitPriceAfterDiscount}
                listPrice={price}
              />
            </div>
            <div className="col-sm-3">
              <small>{t("frontend.cart.quantity")}</small> {quantity}
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-12">
                  {formatCurrency(calculatedExtendedPriceAfterDiscount)}
                  {/* <!--- total ---> */}
                </div>
              </div>
              {isAuthenticated() && showActions && (
                <div className="row">
                  <div className="col-12">
                    <button
                      className="link-button"
                      onClick={(event) => {
                        event.preventDefault();
                        setModal(!showModal);
                      }}
                    >
                      {t("frontend.cart.writeReview")}
                    </button>
                  </div>
                </div>
              )}
              {showActions && (
                <div className="row">
                  <div className="col-12">
                    <button
                      className="link-button"
                      onClick={(event) => {
                        event.preventDefault();
                        dispatch(addToCartOrQuote(sku_skuID, quantity));
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      {t("frontend.cart.reOrder")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          show={showModal}
          setShow={setModal}
          title={t("frontend.product.review.heading")}
          modalClass="addReviewModal"
          size="large"
        >
          <div className="container">
            <AddReview
              setModal={setModal}
              showModal={showModal}
              selectedRate={selectedRate}
              setSelectedRate={setSelectedRate}
              hoveredRate={hoveredRate}
              setHoveredRate={setHoveredRate}
              sku_product_productID={sku_product_productID}
              sku_product_productName={sku_product_productName}
              sku_product_urlTitle={sku_product_urlTitle}
              sku_skuID={sku_skuID}
              images={images}
            />
          </div>
        </Modal>
        <div className="row">
          {childBundleItems &&
            childBundleItems.length > 0 &&
            childBundleItems.map((childBundleItem, key) => {
              return key !== 0 ? (
                <>
                  <i className="bi bi-plus-circle col-1 align-self-center"></i>
                  <Link
                    key={childBundleItem.OrderItemID}
                    className="col-2"
                    to={`/${productRouting}/${childBundleItem.sku_product_urlTitle}`}
                  >
                    <SimpleImage
                      className="img-fluid  m-auto image_container productImage border border-light"
                      src={childBundleItem.images?.at(0)}
                      alt={childBundleItem?.sku_product_productName}
                      type="product"
                    />
                    <span className="text-dark">
                      {" "}
                      {`${formatCurrency(childBundleItem.price)} x ${childBundleItem.quantity}`}
                    </span>
                    <p>{childBundleItem?.sku_product_productName}</p>
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    key={childBundleItem.OrderItemID}
                    className="col-2"
                    to={`/${productRouting}/${childBundleItem.sku_product_urlTitle}`}
                  >
                    <SimpleImage
                      className="img-fluid  m-auto image_container productImage border border-light"
                      src={childBundleItem.images?.at(0)}
                      alt={childBundleItem?.sku_product_productName}
                      type="product"
                    />
                    <span className="text-dark">
                      {" "}
                      {`${formatCurrency(childBundleItem.price)} x ${childBundleItem.quantity}`}
                    </span>
                    <p>{childBundleItem?.sku_product_productName}</p>
                  </Link>
                </>
              );
            })}
        </div>
      </div>
      <div className="card d-block d-lg-none">
        <div className="card-header">
          <h4 className="mb-0 p-0">
            <Link className="link" to={`/${productRouting}/${sku_product_urlTitle}?skuid=${sku_skuID}`}>
              {sku_product_productName}
            </Link>
          </h4>
        </div>
        <ul className="list-group list-group-flush ">
          <li className="list-group-item d-flex justify-content-between ">
            <span className="float-end">
              <div className="row col-12">
                <Link
                  to={`/${productRouting}/${sku_product_urlTitle}?skuid=${sku_skuID}`}
                  className="col-3 mx-auto mr-sm-4"
                >
                  {images && images?.length > 0 && (
                    <SimpleImage
                      className="border img-fluid  m-auto image_container productImage"
                      src={images?.at(0)}
                      alt={sku_product_productName}
                      type="product"
                    />
                  )}
                </Link>
                <div className="col-9">
                  <div className=" font-size-sm">
                    {BrandName} <span className="text-muted mr-2">{sku_calculatedSkuDefinition}</span>
                  </div>
                </div>
              </div>
            </span>
          </li>
          <li className="list-group-item d-flex justify-content-between ">
            <h6 className="my-0"> {t("frontend.product.price")}</h6>
            <span className="float-end">
              <strong>{`${formatCurrency(price)}`}</strong>
            </span>
          </li>
          <li className="list-group-item d-flex justify-content-between ">
            <h6 className="my-0"> {t("frontend.cart.quantity")}</h6>
            <span className="float-end">
              <strong>{quantity}</strong>
            </span>
          </li>
          <li className="list-group-item d-flex justify-content-between ">
            <h6 className="my-0"> {t("frontend.cart.total")}</h6>
            <span className="float-end">
              <strong>{formatCurrency(calculatedExtendedPriceAfterDiscount)}</strong>
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};

const OrderShipments = ({ shipments, files }) => {
  const { t } = useTranslation();
  return (
    <div className="order-items mr-3">
      {shipments &&
        shipments.map((shipment, index) => {
          return (
            <div className="card mb-4" key={index}>
              <div className="card-header bg-secondary text-light">
                <div className="row">
                  <div className="col-sm-6">{`Shipment ${index + 1} of ${shipments.length}`}</div>
                  {shipment.trackingNumber && (
                    <div className="col-sm-6 text-right">
                      {"Tracking Number: "}
                      <a href="/#to-shipper" target="_blank">
                        {shipment.trackingNumber}
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div className="card-header">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <h4 className="p-0">{t("frontend.cart.orderItem")}</h4>
                  </div>
                  <div className="col-sm-12 col-md-6 d-none d-md-block">
                    <div className="row">
                      <div className="col-sm-3">
                        <small>{t("frontend.product.price")}</small>
                      </div>
                      <div className="col-sm-3">
                        <small>{t("frontend.cart.quantity")}</small>
                      </div>
                      <div className="col-sm-6">
                        <small>{t("frontend.cart.total")}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body py-0">
                {shipment.orderItems &&
                  shipment.orderItems.map((item, index) => {
                    return <OrderItem key={index} {...item} files={files} />;
                  })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

const OrderFulfilments = ({ fulfilments, files }) => {
  const {
    CommonModule: { ShippingAddressDetails, PickupLocationDetails },
  } = useElementContext();
  const { t } = useTranslation();
  return (
    <div className="order-items mr-3">
      {fulfilments &&
        fulfilments.map((fulfilment, index) => {
          return (
            <div className="card mb-4" key={index}>
              <div className="card-header bg-secondary text-light">
                <div className="row">
                  <div className="col-sm-6">{`${t("frontend.checkout.fulfillment")} ${index + 1} of ${
                    fulfilments.length
                  }: ${fulfilment.orderFulfillmentMethodName}`}</div>
                  {fulfilment.trackingNumbers.length > 0 && (
                    <div className="col-sm-6 text-right">{`Tracking: ${fulfilment.trackingNumbers.join(", ")}`}</div>
                  )}
                </div>
              </div>

              {fulfilment.fulfillmentMethodType === "shipping" && (
                <div className="card-header ">
                  <div className="row">
                    <ShippingAddressDetails
                      className=""
                      orderFulfillment={{
                        shippingAddress: transformShipping(fulfilment.orderFulfillmentItems.at(0)),
                      }}
                      displayOnly={true}
                    />
                  </div>
                </div>
              )}
              {fulfilment.fulfillmentMethodType === "pickup" && (
                <div className="card-header ">
                  <div className="row">
                    <PickupLocationDetails
                      className=""
                      pickupLocation={transformPickup(fulfilment.orderFulfillmentItems.at(0))}
                      displayOnly={true}
                    />{" "}
                  </div>
                </div>
              )}

              <div className="card-header d-none d-lg-block">
                <div className="row">
                  <div className="col-sm-6 col-6">
                    <h4 className="p-0">{t("frontend.cart.orderItem")}</h4>
                  </div>
                  <div className="col-sm-6 col-6">
                    <div className="row">
                      <div className="col-sm-3 col">
                        <small>{t("frontend.product.price")}</small>
                      </div>
                      <div className="col-sm-3 col">
                        <small>{t("frontend.cart.quantity")}</small>
                      </div>
                      <div className="col-sm-6 col">
                        <small>{t("frontend.cart.total")}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                {fulfilment.orderFulfillmentItems &&
                  fulfilment.orderFulfillmentItems
                    .filter((item) => !item.parentOrderItem_orderitemID)
                    .map((item) => {
                      return (
                        <OrderItem
                          key={item.orderItemID}
                          {...item}
                          files={files}
                          orderChildItems={fulfilment.orderFulfillmentItems.filter(
                            (item) => item.parentOrderItem_orderitemID,
                          )}
                        />
                      );
                    })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export { OrderShipments, OrderFulfilments, OrderItem };

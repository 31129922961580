import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useElementContext } from "../../../contexts";
import { axios, getSdkURL } from "../../../services";
import { getProductRoute, getWishlistsItems } from "../../../selectors";
import { ProductModule } from "../../../modules";
import { Link } from "react-router-dom";
import { SimpleImage } from "../../SWImage/SWImage";
import { useFormatCurrency } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { getWishListItems, receiveCart, removeWishlistItem } from "../../../actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../utils";

const AccountListItems = ({ listID = "" }) => {
  const wishListItems = useSelector(getWishlistsItems);
  const filteredList = wishListItems.filter((item) => listID === item.orderTemplate_orderTemplateID);

  const {
    CommonModule: { AccountLayout },
  } = useElementContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);
  const [selectedSku, setSelectedSku] = useState([]);
  const [quoteModal, setQuoteModal] = useState(false);
  const skuIDs = selectedSku?.map((item) => item.sku_skuID).join(",");
  const quantities = selectedSku?.map((item) => item.quantity).join(",");
  const orderTemplateItemIDs = selectedSku.map((item) => item.orderTemplateItemID).join(",");

  // Removing Wishlist Items in Bulk Code
  const removeWishlistItems = () => {
    if (selectedSku.length > 0) {
      MySwal.fire({
        icon: "info",
        title: <p>Are you sure, You want to delete the items?</p>,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Yes, Delete it!",
      }).then((data) => {
        if (data.isConfirmed) {
          axios({
            method: "POST",
            withCredentials: true,
            url: `${getSdkURL()}api/scope/deleteOrderTemplateItems`,
            data: { orderTemplateItemIDs },
            headers: {
              "Content-Type": "application/json",
            },
          }).then((response) => {
            if (response?.status === 200 && response?.data?.failureActions.length === 0) {
              dispatch(getWishListItems(true));
              setSelectedSku([]);
              toast.success("Item deleted Succesfully!");
            } else {
              toast.error("Error Found!");
            }
          });
        }
      });
    } else {
      toast.error(t("frontend.list.noItemSelectedtoRemove"));
    }
  };

  // Adding Wishlist items in bulk to cart
  const addOrderItems = (params) => {
    if (selectedSku.length > 0) {
      axios({
        method: "POST",
        url: `${getSdkURL()}api/scope/addOrderItems`,
        data: {
          skuIDs,
          quantities,
          returnJSONObjects: "cart",
          ...params,
        },
      }).then((response) => {
        if (response?.status === 200 && response?.data?.failureActions.length === 0) {
          dispatch(receiveCart(response.data.cart));
          if (!!params) {
            toast.success("Added to Quote");
          } else toast.success("Added to Cart");
          dispatch(getWishListItems(true));
          setSelectedSku([]);
        } else toast.error(getErrorMessage(response?.data?.failureActions));
      });
    } else {
      toast.error(t("frontend.list.noItemSelectedtoAdd"));
    }
  };

  // Maintaining checkboxes in state
  const changeEvent = (e, orderItem) => {
    if (e?.target?.name === "select_all_todo") {
      if (e?.target?.checked) {
        setSelectedSku(filteredList.map((product) => product));
      } else {
        setSelectedSku([]);
      }
    } else {
      const skuID = e.target.value;
      if (e?.target?.checked) {
        setSelectedSku((wishList) => Array.from(new Set([...wishList, orderItem])));
      } else {
        setSelectedSku((selectedSkus) =>
          selectedSkus.filter((item) => {
            return item.sku_skuID !== skuID;
          }),
        );
      }
    }
  };

  return (
    <AccountLayout>
      <>
        {filteredList.length > 0 ? (
          <>
            <div className="d-flex justify-content-between align-items-center mt-4">
              <div className="d-flex">
                <input
                  name="select_all_todo"
                  type={"checkbox"}
                  onChange={(e) => changeEvent(e)}
                  checked={selectedSku.length === filteredList.length}
                />
                <h4 className="ms-3 pt-2">
                  Items in {filteredList?.at(0).orderTemplate_orderTemplateName + ` (` + filteredList.length + `)`}
                </h4>
              </div>

              <div>
                <div className="accordion accordion-cart" id="accountListItemsActions">
                  <div className="accordion-item quote-input-box d-flex">
                    <button
                      className="p-2 rounded-0 accordion-button roudned-0 flex-grow-1 collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#${"listItem_accordion"}`}
                      aria-expanded="false"
                      aria-controls={"listItem_accordion"}
                    >
                      <span className="p-1">Bulk Actions</span>
                    </button>
                    <div
                      id={"listItem_accordion"}
                      className="accordion-collapse accordion-absolute collapse"
                      aria-labelledby="cart-headingOne"
                      data-bs-parent="#accountListItemsActions"
                    >
                      <div className="accordion-body p-3">
                        <div className="row">
                          <button className="p-2 bg-transparent border-0" onClick={() => addOrderItems()}>
                            Add to cart
                          </button>
                          <button
                            className="p-2 bg-transparent border-0"
                            onClick={() =>
                              selectedSku.length > 0
                                ? setQuoteModal((prevState) => !prevState)
                                : toast.error(t("frontend.list.quote.noItemSelectedtoAdd"))
                            }
                          >
                            Add to quote
                          </button>
                          <button className="p-2 bg-transparent border-0" onClick={() => removeWishlistItems()}>
                            {t("frontend.listfolders.removeSelected")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {quoteModal && (
              <ProductModule.CoreComponents.AddProductToQuoteModal
                skuIDs={skuIDs || ""}
                setQuoteModal={setQuoteModal}
                quantities={quantities}
              />
            )}
            <div className="row py-3 cart-item-box">
              {filteredList?.map((orderItem) => {
                return (
                  <AccountListItem
                    orderItem={orderItem}
                    selectedSku={selectedSku}
                    changeEvent={changeEvent}
                    listID={listID}
                  />
                );
              })}
            </div>
            <div>
              <span>
                {selectedSku.length === 0 && <i>Please select item(s)</i>}
                {selectedSku.length > 0 && (
                  <i>
                    Selected {selectedSku.length} of {filteredList.length} items
                  </i>
                )}
              </span>
            </div>
          </>
        ) : (
          <div className="alert alert-info mt-4" role="alert">
            {t("frontend.list.noresults")}
          </div>
        )}
      </>
    </AccountLayout>
  );
};

const AccountListItem = ({ orderItem, selectedSku, changeEvent }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formatCurrency] = useFormatCurrency({});
  const productRouting = useSelector(getProductRoute);
  const [itemCount, setItemCount] = useState(orderItem.quantity);

  const filteredListItem = selectedSku.filter((sku) => orderItem.sku_skuID === sku.sku_skuID);

  const updateWishlistItemQuantity = (quantity, orderTemplate_orderTemplateID, orderTemplateItemID) => {
    axios({
      method: "POST",
      withCredentials: true,
      url: `${getSdkURL()}api/scope/updateWishlistItemQuantity`,
      data: {
        quantity,
        orderTemplateItemID,
        orderTemplateID: orderTemplate_orderTemplateID,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response?.status === 200 && response?.data?.failureActions.length === 0) {
        dispatch(getWishListItems(true));
        toast.success("Quantity updated Succesfully!");
      } else {
        toast.error("Error Found!");
      }
    });
  };

  return (
    <div className="row detail my-3" key={orderItem?.sku_skuID}>
      <div className="col-sm-2 col-2 image d-flex">
        <input
          checked={filteredListItem.length > 0 ? true : false}
          type={"checkbox"}
          value={orderItem?.sku_skuID}
          onChange={(e) => changeEvent(e, orderItem)}
          name={`listItem_${orderItem?.sku_skuID}`}
        />
        {orderItem.images && orderItem.images?.length > 0 && (
          <SimpleImage
            className="img-fluid ms-3 m-auto image_container productImage"
            src={orderItem.images?.at(0)}
            alt={orderItem.sku_product_productName}
            type="product"
          />
        )}
      </div>
      <div className="col-sm-4 col-9">
        <div className="title">
          <h4>
            <Link
              to={{
                pathname: `/${productRouting}/${orderItem.sku_product_urlTitle}`,
                // @ts-ignore
                state: { ...orderItem.sku_product },
              }}
              className="text-decoration-none text-dark"
            >
              {orderItem.sku_product_productName}
            </Link>
          </h4>
        </div>
        <div className="font-size-sm item-sku">
          <span className="mr-2">
            {t("frontend.product.sku")} {orderItem.sku_skuCode}
          </span>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 d-flex bottom-detail-box d-none d-md-block">
        <div className="row">
          <div className="item-price col-sm-2">
            <ProductModule.CoreComponents.ProductPrice
              type="cart"
              salePrice={orderItem.sku_salePrice}
              listPrice={orderItem.sku_listPrice}
            />
          </div>

          <div className="number-range col-sm-4">
            <input
              type="number"
              className=""
              value={itemCount}
              disabled={!orderItem.sku_skuID}
              onChange={(e) => setItemCount(e.target.value)}
            />
            <button
              className="btn text-muted btn-link p-1 text-end"
              onClick={() =>
                updateWishlistItemQuantity(
                  itemCount,
                  orderItem.orderTemplate_orderTemplateID,
                  orderItem.orderTemplateItemID,
                )
              }
            >
              {t("frontend.account.cart.item.updateQuantity")}
            </button>
          </div>

          <div className="col-sm-3">
            <h5 className="total-price">{formatCurrency(orderItem.total)}</h5>
          </div>
          <div className="item-delete-btn col-sm-2">
            <span
              className={`bi bi-trash clickable`}
              onClick={() => dispatch(removeWishlistItem(orderItem.sku_skuID, orderItem.orderTemplate_orderTemplateID))}
            ></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AccountListItems };

import { MouseEventHandler, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

export interface ButtonProps {
  disabled?: boolean;
  classList?: string;
  type?: "button" | "submit";
  isLoading?: boolean;
  label?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

/**
 * Primary UI component for user interaction
 */
const Button = ({
  disabled = false,
  classList = "btn btn-primary btn-block",
  type = "button",
  isLoading = false,
  children,
  label = "",
  onClick,
}: PropsWithChildren<ButtonProps>) => {
  const { t } = useTranslation();
  return (
    <button type={type} disabled={disabled} className={classList} onClick={onClick}>
      {children}
      {!children && t(label)}
      {isLoading && <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true" />}
    </button>
  );
};
export { Button };

//COMPONENTS
import { ProductAttributes } from "./Components/ProductAttributes/ProductAttributes.component";
import { ProductForm } from "./Components/ProductForm/ProductForm.component";
import { ProductDetailGallery } from "./Components/ProductImageGallery/ProductDetailGallery.component";
import { ProductDetailHeading } from "./Components/ProductDetailHeading/ProductDetailHeading.component";
import { ProductPrice } from "./Components/ProductPrice/ProductPrice.component";
import { SkuOptions } from "./Components/SkuOptions/SkuOptions.component";
import { HeartButton } from "./Components/HeartButton/HeartButton.component";
import { ProductBundle } from "./Components/ProductBundle/ProductBundle.component";
import { SkuSelector } from "./Components/SkuSelector/SkuSelector.component";
import { RelatedProductsSlider } from "./Components/RelatedProductsSlider/RelatedProductsSlider.component";
import { ProductOutOfStock } from "./Components/ProductOutOfStock/ProductOutOfStock.component";
import { ProductBundleItems } from "./Components/ProductBundleItems/ProductBundleItems.component";
import { AddProductToQuoteModal } from "./Components/AddProductToQuoteModal/AddProductToQuoteModal.component";
import { AddProductToListModal } from "./Components/AddProductToListModal/AddProductToListModal.component";
import { ProductTypeList } from "./Components/ProductTypeList/ProductTypeList.component";

//CONTROLS
import { ProductAdditionalInformation } from "./Controls/ProductAdditionalInformation";
import { ProductMultiCartButtons } from "./Controls/ProductMultiCartButtons";
import { OptionButtons } from "./Controls/OptionButtons";
import { OptionDropdown } from "./Controls/OptionDropdown";
import { ProductQuantityInput } from "./Controls/ProductQuantityInput";
import { BundleConfig } from "./Controls/BundleConfig";
import { BundleItem } from "./Controls/BundleItem";
import {
  ProductConfiguratorSelector,
  ProductConfiguratorDropdown,
  ProductConfiguratorRadio,
  ProductConfiguratorSwatch,
} from "./Controls/ProductConfiguratorSelector";
import { ProductConfiguratorModal } from "./Controls/ProductConfiguratorModal";
import { ProductCategories } from "./Controls/ProductCategories";

//CUSTOM

export namespace ProductModule {
  export const CoreComponents = {
    ProductAttributes,
    SkuOptions,
    SkuSelector,
    ProductForm,
    ProductDetailGallery,
    ProductDetailHeading,
    ProductPrice,
    HeartButton,
    ProductBundle,
    ProductBundleItems,
    RelatedProductsSlider,
    ProductOutOfStock,
    AddProductToQuoteModal,
    AddProductToListModal,
    ProductTypeList,
  };

  export const CoreControl = {
    ProductAdditionalInformation,
    ProductMultiCartButtons,
    OptionButtons,
    OptionDropdown,
    ProductQuantityInput,
    BundleConfig,
    BundleItem,
    ProductConfiguratorSelector,
    ProductConfiguratorDropdown,
    ProductConfiguratorRadio,
    ProductConfiguratorSwatch,
    ProductConfiguratorModal,
    ProductCategories,
  };

  export const Custom = {};
}

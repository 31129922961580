import { useTranslation } from "react-i18next";
import { useElementContext } from "../../../contexts";
import { SearchConfig } from "../productListing.moduleModels";

export interface InfiniteScrollButtonProps {
  isFetching: boolean;
  recordsCount: number;
  currentPage: string;
  totalPages: number;
  totalCount: number;
  setPage: (page: number) => void;
  searchConfig: SearchConfig;
}

const InfiniteScrollButton = ({
  isFetching,
  recordsCount,
  currentPage = "1",
  totalPages,
  setPage,
  totalCount,
}: InfiniteScrollButtonProps) => {
  const { t } = useTranslation();
  const {
    CommonModule: { Button },
  } = useElementContext();

  if (!recordsCount) return null;

  return (
    <div className="searchListing_infiniteScrollButton_container">
      {totalCount > recordsCount && (
        <Button
          isLoading={isFetching}
          classList="btn btn-primary searchListing_infiniteScrollButton"
          onClick={() => {
            if (isFetching) return;
            setPage(parseInt(currentPage, 10) + 1);
          }}
        >
          {t("frontend.pagination.loadMore")}
        </Button>
      )}
      <div className="searchListing_infiniteScrollButton_recordCounts">
        {t("frontend.plp.recordsCount", {
          recordsCount,
          totalCount,
        })}
      </div>
    </div>
  );
};

export { InfiniteScrollButton };

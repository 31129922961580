import { useElementContext } from "../../contexts";

const FeatureBanners = (props) => {
  const {
    CommonModule: { ContentCard },
  } = useElementContext();
  const { systemCode, innerElements, contentElementName, customStyleClasses } = props;
  return (
    <section className={`feature-banners py-4 ${systemCode} ${customStyleClasses}`}>
      {contentElementName && <h2 className="text-center">{contentElementName}</h2>}
      {innerElements.map((banner, i) => {
        return <ContentCard key={i} {...banner} />;
        // return <BandSlide key={slide.brandID} {...slide} />
      })}
    </section>
  );
};

export { FeatureBanners };

const initState = {
  data: null,
  params: null,
  scrollY: 0,
};

export const SET_INFINITE_SCROLL_RECORDS = "SET_INFINITE_SCROLL_RECORDS";
export const SET_SCROLL_POSITION = "SET_SCROLL_POSITION";

const infiniteScrollReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_INFINITE_SCROLL_RECORDS:
      return { ...state, data: action.payload.data, params: action.payload.params };
    case SET_SCROLL_POSITION:
      return { ...state, scrollY: action.payload.scrollY };
    default:
      return { ...state };
  }
};

export { infiniteScrollReducer };

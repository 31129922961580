import { ProductCardActionsModel } from "./ProductCardActions.componentModel";
import { PublicInterface } from "../../../../interface/common";
import { useElementContext } from "../../../../contexts";
import { Dispatch, SetStateAction } from "react";

export const ProductCardActionsView = ({
  componentData: { type, showProductModifierModal, ...rest },
  setQuantity,
  onAddToCart,
  setProductModifierModal,
}: {
  componentData: PublicInterface<ProductCardActionsModel>;
  setQuantity: (n: number) => void;
  onAddToCart: ({
    skuConfiguration,
  }?: {
    skuConfiguration?: {
      [key: string]: string;
    };
  }) => void;
  setProductModifierModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const { ProductModule, ProductCardModule } = useElementContext();
  const { sku, isQtyFieldRequired, cardConfiguration, isFetching, itemCount } = rest;
  return (
    <div className="productCard-button-container py-3">
      {isQtyFieldRequired && (
        <>
          <ProductModule.CoreComponents.ProductOutOfStock isFetching={isFetching} sku={sku} />
          <div className="text-center border-0 bg-transparent pt-0 ">
            <div className="productCard_quantityInput">
              <ProductModule.CoreControl.ProductQuantityInput
                setQuantity={setQuantity}
                quantity={itemCount}
                sku={sku}
                showLabel={cardConfiguration?.showInputLabel}
                quantityInputType={cardConfiguration?.input?.quantityInput || "text"}
                showInventory={cardConfiguration?.input?.showInventory || false}
                dropdownLimitCount={cardConfiguration?.input?.dropdownLimitCount || 8}
              />
            </div>
          </div>
        </>
      )}
      {showProductModifierModal && (
        <ProductModule.CoreControl.ProductConfiguratorModal
          sku={sku}
          productConfigurator={rest.productConfigurator!}
          setShow={setProductModifierModal}
          addItem={onAddToCart}
        />
      )}
      <ProductCardModule.CoreControl.DropdownButtons {...rest} onAddToCart={onAddToCart} />
    </div>
  );
};

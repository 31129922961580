import { useMemo, useState } from "react";
import { useElementContext } from "../../../contexts";
import { ProductConfigurator, Sku } from "../../../interface/Product";
import { SearchSku } from "../../../interface/SearchProduct";
import { getConfigurationPrice } from "../../../utils";

export interface ProductConfiguratorModalProps {
  sku: Sku | SearchSku;
  initialSkuConfiguration?: { [key: string]: string };
  productConfigurator: ProductConfigurator;
  setShow: (open: boolean) => void;
  addItem?: ({ skuConfiguration }: { skuConfiguration: { [key: string]: string } }) => void;
}

const ProductConfiguratorModal = ({
  sku,
  productConfigurator,
  setShow,
  addItem,
  initialSkuConfiguration = {},
}: ProductConfiguratorModalProps) => {
  const [skuConfiguration, setSkuConfiguration] = useState<{ [key: string]: string }>(initialSkuConfiguration);
  const showAddItem = useMemo(
    () =>
      productConfigurator.options?.every(
        (configurator) => !configurator.requiredFlag || skuConfiguration[configurator.id],
      ),
    [productConfigurator.options, skuConfiguration],
  );
  const {
    CommonModule: { Modal, Portal },
    ProductModule,
  } = useElementContext();

  const price = useMemo(
    () => getConfigurationPrice(productConfigurator, skuConfiguration),
    [productConfigurator, skuConfiguration],
  );

  return (
    <Portal>
      <Modal setShow={setShow} title="Select product configuration">
        <form
          className="d-flex flex-column gap-4"
          onSubmit={(e) => {
            e.preventDefault();
            addItem?.({ skuConfiguration });
          }}
        >
          <h3 className="p-0 m-0">{sku?.skuName || sku?.product_productName}</h3>

          <ProductModule.CoreComponents.ProductPrice
            listPrice={price}
            salePrice={price}
            showPriceForUserType={sku.settings.skuShowPriceForUserType}
          />

          <ProductModule.CoreControl.ProductConfiguratorSelector
            productConfigurator={productConfigurator}
            setSkuConfiguration={setSkuConfiguration}
            skuConfiguration={skuConfiguration}
          />

          {showAddItem && (
            <button type="submit" className="btn btn-primary">
              Add Item
            </button>
          )}
        </form>
      </Modal>
    </Portal>
  );
};

export { ProductConfiguratorModal };

import { useTranslation } from "react-i18next";
import { AddProductToListModalModel } from "./AddProductToListModal.componentModel";
import { useElementContext } from "../../../../contexts";

export const AddProductToListModalView = ({
  componentData: { existingList, existingListVal, isLoading, isLoadingForExisitingList, listName, isAuthenticated },
  setListModal,
  setExistingListVal,
  setListName,
  onAddToList,
  onCreateList,
}: {
  componentData: AddProductToListModalModel;
  setListModal: (isOpen: boolean) => void;
  setExistingListVal: (value: string) => void;
  setListName: (value: string) => void;
  onAddToList: () => void;
  onCreateList: () => void;
}) => {
  const { t } = useTranslation();
  const {
    CommonModule: { SwSelect, Button, Modal, LoginRequiredModal },
  } = useElementContext();

  if (!isAuthenticated) {
    return <LoginRequiredModal show setShow={setListModal} title={t("frontend.list.addToList")} />;
  }

  return (
    <Modal setShow={setListModal} title={t("frontend.list.addToList")} size="large">
      <div className="container">
        {existingList.length > 0 && (
          <>
            <form name="add-to-exisiting-list">
              <div className="row text-align-center">
                <div className="col-md-12 d-flex justify-content-center">
                  <div className="form-group col-md-4 p-3">
                    <label htmlFor="existingList">{t("frontend.list.selectExistingList")}</label>
                    <SwSelect
                      id="existingList"
                      value={existingListVal}
                      onChange={(e: any) => {
                        setExistingListVal(e.target.value);
                      }}
                      options={existingList.length > 0 ? existingList : []}
                    />
                    {!existingListVal ? <span className="form-error-msg">{t("frontend.core.required")}</span> : null}
                  </div>
                  <div className="form-group p-3">
                    <Button
                      isLoading={isLoadingForExisitingList}
                      classList="btn btn-primary btn-block mt-4"
                      type="button"
                      onClick={onAddToList}
                      disabled={isLoadingForExisitingList}
                    >
                      <span className="d-none d-sm-inline">{t("frontend.list.addExistingList")}</span>
                    </Button>
                  </div>
                </div>
              </div>
            </form>

            <hr className="hr-text" />
          </>
        )}

        <form name="add-list">
          <div className="row text-align-center">
            <div className="col-md-12 d-flex justify-content-center">
              <div className="form-group me-3">
                <label htmlFor="listName">List Name</label>
                <input
                  className="form-control"
                  type="text"
                  id="listName"
                  value={listName}
                  onChange={(e) => {
                    setListName(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <Button
                  isLoading={isLoading}
                  disabled={isLoading}
                  type="button"
                  classList="btn btn-primary btn-block mt-4 d-block m-auto"
                  onClick={onCreateList}
                >
                  <span className="d-sm-inline">{t("frontend.quote.createButton")}</span>
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

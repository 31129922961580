import { ProductCardActionsView } from "./ProductCardActions.componentView";
import { ProductCardActionsProps } from "./ProductCardActions.componentModel";
import { useComponentModel } from "./ProductCardActions.componentService";

export function ProductCardActions(props: ProductCardActionsProps) {
  const { componentData, componentService } = useComponentModel({ ...props });

  if (!componentData.buttonStack?.length) return null;

  const onAddToCart = ({ skuConfiguration }: { skuConfiguration?: { [key: string]: string } } = {}) => {
    componentService.onAddToCart(componentData.itemCount, skuConfiguration);
  };

  //CUSTOM BUSINESS LOGIC HERE
  return (
    <ProductCardActionsView
      componentData={componentData}
      setQuantity={componentService.setQuantity}
      onAddToCart={onAddToCart}
      setProductModifierModal={componentService.setProductModifierModal}
    />
  );
}

import { useState, useRef, useMemo } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import isEqual from "fast-deep-equal";
import { useFormatCurrency } from "../../hooks";
import { toast } from "react-toastify";
import { formatSkuConfigurationPayload, getErrorMessage } from "../../utils";
import { getSdkURL, axios } from "../../services";
import { useElementContext, useServiceContext } from "../../contexts";

const QuickAddTab = ({ orderID, getOrderInfo }) => {
  const typeaheadRef = useRef(null);
  const [formatCurrency] = useFormatCurrency({});
  const [showSkuModifiers, setShowSkuModifiers] = useState(null);
  const [products, setProducts] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [orderItems, setorderItems] = useState([]);
  const [isAddingProduct, setAddingProduct] = useState(false);
  const { ProductService } = useServiceContext();
  const productService = useMemo(() => new ProductService(), [ProductService]);
  const {
    ProductModule,
    CommonModule: { SelectedSkuConfiguration },
  } = useElementContext();

  const filterBy = () => true;

  const addOrderItems = () => {
    if (isAddingProduct) return;
    setAddingProduct(true);
    getOrderInfo({ makeRequest: false });

    const skus = orderItems.map((item) => ({
      skuID: item.skuID,
      quantity: item.quantity,
      ...formatSkuConfigurationPayload(item.skuConfiguration),
    }));

    axios({
      method: "POST",
      url: `${getSdkURL()}api/scope/addOrderItemsV2`,
      data: {
        orderID,
        skus,
      },
    })
      .then((response) => {
        if (response?.status === 200 && response?.data?.failureActions.length === 0) {
          setorderItems([]);
          toast.success("Items added successfully");
          getOrderInfo();
        } else {
          toast.error(getErrorMessage(response?.data?.failureActions));
          getOrderInfo({ isFetching: false, makeRequest: false, isLoaded: true });
        }
      })
      .finally(() => {
        setAddingProduct(false);
      });
  };

  const predictiveSearch = (searchstring) => {
    const source = axios.CancelToken.source();
    setisLoading(true);

    productService.searchTypeahead(searchstring, "product", source).then(async ({ data }) => {
      if ((data?.items || []).length > 0) {
        if (process.env.REACT_APP_DELTA_STORE_URL) {
          const items = await Promise.all(
            data.items.map(async (item) => {
              const product = await fetch(
                `${process.env.REACT_APP_DELTA_STORE_URL}/public/ultracommerce/product/transform/byUrlTitle/${item.metadata.urlTitle}`,
              ).then((resp) => resp.json());
              return {
                metadata: {
                  ...item.metadata,
                  modifiers: product.modifiers,
                },
              };
            }),
          );
          setProducts(items);
        } else {
          setProducts(data.items);
        }
        setisLoading(false);
      }
    });
  };

  const handleAddItem = (sku, skuConfiguration) => {
    if (sku.modifiers?.length && !skuConfiguration) {
      setShowSkuModifiers(sku);
      return;
    }

    const itemIndex = orderItems.findIndex((item) => {
      if (item.skuID === sku.skuID) {
        if (!sku.modifiers?.length || isEqual(item.skuConfiguration, skuConfiguration)) {
          return true;
        }
      }
      return false;
    });

    if (itemIndex === -1) {
      setorderItems((prevState) => [...prevState, { ...sku, skuConfiguration, qty: 1 }]);
    } else {
      setorderItems((prevState) => {
        const newState = [...prevState];
        newState[itemIndex] = { ...prevState[itemIndex], qty: prevState[itemIndex].qty + 1 };
        return newState;
      });
    }

    setTimeout(() => typeaheadRef.current.clear(), 800);
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        addOrderItems();
      }}
    >
      <div className="d-flex justify-content-end p-2 my-2">
        <button className="btn btn-primary" type="submit" disabled={!orderItems.length}>
          {isAddingProduct ? (
            <span className="spinner-border spinner-border-sm p-2" role="status" aria-hidden="true"></span>
          ) : (
            "Add To Order"
          )}
        </button>
      </div>
      <div className="col-12 table-responsive">
        <table className="table w-100">
          <thead>
            <tr>
              <th></th>
              <th className="ps-1">Product Code</th>
              <th></th>
              <th>Quantity</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {orderItems.length > 0 ? (
              orderItems.map((orderItem, index) => {
                return (
                  <tr key={index} className="p-2 align-middle">
                    <td style={{ width: "20px" }}>
                      <i
                        className="bi bi-trash3 text-primary"
                        role="button"
                        onClick={() => {
                          setorderItems((prevState) => prevState.filter((_, i) => i !== index));
                        }}
                      ></i>
                    </td>
                    <td className="p-1 pe-3" style={{ minWidth: "200px" }}>
                      {orderItem?.title || orderItem?.description}
                      {orderItem.modifiers?.length && orderItem.skuConfiguration && (
                        <SelectedSkuConfiguration
                          modifiers={orderItem.modifiers}
                          skuConfiguration={orderItem.skuConfiguration}
                        />
                      )}
                    </td>
                    <td className="p-1" style={{ minWidth: "100px" }}>
                      {formatCurrency(orderItem.salePrice)}
                    </td>
                    <td className="p-1" style={{ width: "100px" }}>
                      <input
                        min={1}
                        required
                        type="number"
                        className="form-control"
                        style={{ borderRadius: "30px" }}
                        name="qty"
                        value={orderItem.qty}
                        onChange={(e) => {
                          setorderItems((prevState) => {
                            const newState = [...prevState];
                            newState[index] = {
                              ...prevState[index],
                              qty: e.target.value,
                            };
                            return newState;
                          });
                        }}
                      />
                    </td>
                    <td className="p-1 font-weight-bold ps-3">{formatCurrency(orderItem.qty * orderItem.salePrice)}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  Please search by sku to add products
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="col-md-8 col-lg-4">
        <AsyncTypeahead
          className="border m-0 quickAdd-typeahead"
          filterBy={filterBy}
          id="postalCode"
          isLoading={isLoading}
          labelKey={(option) => option?.metadata?.title || option?.metadata?.description}
          minLength={3}
          onSearch={predictiveSearch}
          options={products}
          placeholder="Search sku"
          ref={typeaheadRef}
          onChange={(selected) => {
            if (selected.length > 0) {
              handleAddItem(selected[0].metadata);
            }
          }}
        />
        {showSkuModifiers && (
          <ProductModule.CoreControl.ProductModifiersModal
            product={showSkuModifiers}
            setShow={setShowSkuModifiers}
            addItem={(skuConfiguration) => {
              handleAddItem(showSkuModifiers, skuConfiguration);
              setShowSkuModifiers(false);
            }}
          />
        )}
      </div>
    </form>
  );
};

export default QuickAddTab;

import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { getErrorMessage, isAuthenticated } from "../../../../utils";

import { setAllCartOrQuotes } from "../../../../actions/cartActions";
import { setSelectedCartOrQuotes } from "../../../../actions/configActions";
import { removeItem, removeOrderTemplateItem } from "../../../../actions";
import { MiniCartItemProps } from "../MiniCartItem";
import { Cart, OrderItem } from "../../../../interface/Cart";
import { MiniCartModel, MiniCartProps } from "./MiniCart.componentModel";
import { getSiteConfig } from "../../../../selectors";

export const useComponentData = ({ color }: MiniCartProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cart: Cart = useSelector((state: any) => state.cart);
  const orderTemplateCart = useSelector((state: any) => state.subscriptionCart);
  const cartAndQuotes = useSelector((state: any) => state?.allCartQuote?.cartAndQuote);
  const selectedCartOrQuote = useSelector((state: any) => state.cartQuoteReducer);
  const { theme } = useSelector(getSiteConfig);
  const miniCartShowQuote = theme.miniCartShowQuote ?? true;

  const [miniCartOpen, setMiniCartOpen] = useState(false);
  const [quoteModal, setQuoteModal] = useState(false);
  const [isRemovingItem, setRemovingItem] = useState(false);

  const { isFetching, orderItems, total } = cart;
  const { orderTemplateItems, orderTemplateID, calculatedSubTotal } = orderTemplateCart;

  useEffect(() => {
    dispatch<any>(setAllCartOrQuotes());
  }, [dispatch]);

  useEffect(() => {
    if (cartAndQuotes?.length) {
      dispatch<any>(
        setSelectedCartOrQuotes(
          isAuthenticated() && localStorage.getItem("selectedCartOrQuote")
            ? cartAndQuotes?.filter(
                (item: any) =>
                  item.orderID === JSON.parse(localStorage.getItem("selectedCartOrQuote") || "{}")?.orderID,
              ).length > 0
              ? JSON.parse(localStorage.getItem("selectedCartOrQuote") || "{}")
              : {}
            : {},
        ),
      );
    }
  }, [dispatch, cartAndQuotes]);

  const cartAction = useMemo(
    () => ({
      removeItem: async (productItem: OrderItem) => {
        if (isRemovingItem) return;
        setRemovingItem(true);
        await dispatch<any>(removeItem(productItem));
        setRemovingItem(false);
      },

      getPrice: (productItem: OrderItem) => productItem.extendedUnitPriceAfterDiscount,
    }),
    [dispatch, isRemovingItem],
  );

  const orderTemplateCartAction = useMemo(
    () => ({
      removeItem: (productItem: any) => {
        const { orderTemplateItemID } = productItem;
        dispatch<any>(removeOrderTemplateItem(orderTemplateID, orderTemplateItemID)).then((response: any) => {
          if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length)
            toast.error(getErrorMessage(response.success().errors));
          if (response.isSuccess()) toast.success(t("frontend.cart.removeCartItem"));
        });
      },
      getPrice: (productItem: any) => productItem.calculatedTotal,
    }),
    [t, dispatch, orderTemplateID],
  );

  const cartList = useMemo(() => {
    const list: MiniCartItemProps["cartItem"][] = [];
    list.push({
      id: "default",
      title: theme.miniCartTitle || t("frontend.checkout.cart"),
      items: orderItems,
      totalItems: orderItems.length || 0,
      subTotal: total,
      pathLabel: theme.miniCartLinkTitle || t("frontend.cart.viewCart"),
      pathname: theme.miniCartLinkUrl || "/shopping-cart",
      isFetching: isFetching,
      isRemovingItem: isRemovingItem,
      cartAction: cartAction,
      isActive: !selectedCartOrQuote?.orderID,
      buttons: theme.miniCartButtons || [
        { btn_style: "btn-dark", cta_target: "_self", cta_title: "Checkout", cta_url: "/checkout", id: 1 },
      ],
    });
    if (miniCartShowQuote) {
      cartAndQuotes
        ?.filter((order: any) => order.orderTypeCode === "otQuoteOrder")
        .forEach((quote: any) => {
          list.push({
            id: quote.orderID,
            title: quote.quoteName,
            items: [],
            totalItems: quote.totalItems,
            subTotal: 0,
            pathLabel: "View Quote", //t("frontend.cart.viewCart"),
            pathname: `/my-account/quotes/${quote.orderID}`,
            isFetching: false,
            isRemovingItem: false,
            cartAction: {},
            isActive: selectedCartOrQuote?.orderID === quote.orderID,
            buttons: [],
          });
        });
    }

    if (orderTemplateItems?.length) {
      list.push({
        id: "subscription-cart",
        accordionItemClass: "subscription-cart",
        title: t("frontend.cart.orderTemplateCart"),
        items: orderTemplateItems,
        totalItems: orderTemplateItems.length,
        subTotal: calculatedSubTotal,
        pathLabel: t("frontend.cart.viewSubscriptionCart"),
        pathname: "/scheduled-delivery-cart",
        isFetching: false,
        cartAction: orderTemplateCartAction,
        isRemovingItem: isRemovingItem,
        isActive: true,
        buttons: theme.miniCartButtons || [
          { btn_style: "btn-dark", cta_target: "_self", cta_title: "Checkout", cta_url: "/checkout", id: 1 },
        ],
      });
    }

    return list;
  }, [
    theme.miniCartTitle,
    theme.miniCartLinkTitle,
    theme.miniCartLinkUrl,
    theme.miniCartButtons,
    t,
    orderItems,
    total,
    isFetching,
    isRemovingItem,
    cartAction,
    selectedCartOrQuote?.orderID,
    miniCartShowQuote,
    orderTemplateItems,
    cartAndQuotes,
    calculatedSubTotal,
    orderTemplateCartAction,
  ]);
  return {
    componentData: new MiniCartModel({
      cartList,
      miniCartOpen,
      cart,
      quoteModal,
      color,
      miniCartStyle: theme.miniCartStyle || "Dropdown",
      miniCartShowQuote,
      miniCartSize: theme.miniCartSize || "350px",
    }),
    componentService: { setMiniCartOpen, setQuoteModal },
  };
};

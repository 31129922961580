import { FacetFilterProps } from "./FacetFilter.componentModel";
import { useComponentData } from "./FacetFilter.componentService";
import { FacetFilterView } from "./FacetFilter.componentView";

const FacetFilter = (props: FacetFilterProps) => {
  const { filter } = props;
  const { componentData, componentService } = useComponentData(props);

  if (!filter.options.length) return null;

  return <FacetFilterView componentData={componentData} {...componentService} />;
};

export { FacetFilter };

import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useElementContext } from "../../../contexts";

function NoProductFound({ noProductFoundLink = { label: "No Products Found", link: "/contact" } }) {
  const { t } = useTranslation();
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const query = queryString.parse(search);
  const {
    CommonModule: { SearchBar },
  } = useElementContext();

  const isFilterApplied = Object.keys(query).some((filter) => {
    return !!query[filter] && filter.startsWith("facets_");
  });

  return (
    <div className="col text-center p-4 bg-lightgray">
      <h3 className="pt-2">{t("frontend.product.noProductsFound")}</h3>
      {query.keyword && (
        <div className="my-3">
          <h5 className="pb-2">{t("frontend.product.noKeywordSearch")}</h5>

          <div className="row d-flex justify-content-center">
            <div className="col-6 col-md-8 col-sm-12">
              <SearchBar />
            </div>
          </div>
        </div>
      )}

      {isFilterApplied && (
        <button
          className="btn btn-primary btn-sm "
          onClick={() => {
            navigate(pathname);
          }}
        >
          {t("frontend.product.removeFilter")}
        </button>
      )}
      <p className="mt-4">
        {t("frontend.product.needAssistance")}
        <Link className="mx-2" to={noProductFoundLink.link}>
          <u>{noProductFoundLink.label}</u>
        </Link>
      </p>
    </div>
  );
}

export { NoProductFound };

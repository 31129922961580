import { getBrandRoute, getProductRoute } from "../../../selectors";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { SearchProduct, SearchSku } from "../../../interface/SearchProduct";
import { removeWishlistItem } from "../../../actions/userActions";
import { toast } from "react-toastify";
import { useElementContext } from "../../../contexts";
import { useTranslation } from "react-i18next";

interface CardConfiguration {
  showInput: boolean;
  showPrice: boolean;
  showSkuCode: boolean;
  showProductCode: boolean;
  showBrand: boolean;
  enableFavorites: boolean;
  buttons: [];
  removeFromList: boolean;
  orderTemplate?: { orderTemplateID?: string; orderTemplateName?: string };
}

export interface SkuCardWithDetailProps {
  product: SearchSku;
  cardConfiguration?: CardConfiguration;
}

const SkuCardWithDetail = ({
  product,
  cardConfiguration = {
    showInput: true,
    showPrice: true,
    showSkuCode: true,
    showProductCode: false,
    showBrand: false,
    enableFavorites: false,
    buttons: [],
    removeFromList: false,
    orderTemplate: {},
  },
}: SkuCardWithDetailProps) => {
  const {
    skuName: name,
    skuDescription: description,
    options,
    skuCode,
    skuID,
    product_urlTitle: urlTitle,
    salePrice,
    listPrice,
    settings,
    product_brand_brandName: brandName,
    product_brand_urlTitle: brandUrlTitle,
    product_productCode: productCode,
  } = product;
  const {
    ProductModule,
    ProductCardModule,
    CommonModule: { Truncate },
  } = useElementContext();
  const { t } = useTranslation();
  const skuUom = options && options?.length > 0 ? options[0].optionGroupName : "";
  const productRoute = useSelector(getProductRoute);
  const brandRoute = useSelector(getBrandRoute);
  const productLink = `/${productRoute}/${urlTitle}` + (skuID?.length ? `?skuid=${skuID}` : "");
  const dispatch = useDispatch();
  const removeItemFromList = () => {
    dispatch<any>(removeWishlistItem(skuID, cardConfiguration?.orderTemplate?.orderTemplateID)).then((res: any) => {
      if (res.isSuccess()) {
        toast.success("Item removed from the list successfully!");
      }
    });
  };

  return (
    <div className="card productCardWithDetail p-3 my-2">
      <div
        className="d-md-grid d-sm-flex flex-column flex-md-row align-items-center align-items-md-start"
        style={{ gridTemplateColumns: "1fr 4fr" }}
      >
        <div className="d-flex flex-column align-items-center flex-shrink-0 h-100 w-auto">
          <Link to={productLink} style={{ maxWidth: 160 }}>
            <ProductCardModule.CoreControl.ProductCardImage product={product} />
          </Link>
          {skuID && cardConfiguration.enableFavorites && (
            <ProductModule.CoreComponents.HeartButton skuID={skuID} className=" align-self-center link btn-wishlist" />
          )}
        </div>

        <div className="w-100">
          <div
            className="d-sm-flex d-md-grid flex-wrap align-content-start gap-4"
            style={{ gridTemplateColumns: "2fr 2fr" }}
          >
            <div className="col-12">
              <Link to={productLink} className="product-name">
                <h5 className="mx-1 pt-2">{name}</h5>
              </Link>
              {cardConfiguration?.showBrand && (
                <Link to={`/${brandRoute}/${brandUrlTitle}`} className="text-capitalize product-brand mx-1">
                  {brandName}
                </Link>
              )}
              <Truncate lineClamp="5">
                <div
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              </Truncate>
            </div>
            <div className="col-12">
              {cardConfiguration.showProductCode && productCode && <div>Product Code: {productCode}</div>}
              {cardConfiguration.showSkuCode && (
                <>
                  <div>
                    {t("frontend.product.sku")} {skuCode}
                  </div>
                  {skuUom !== "" && <div>UOM : {skuUom}</div>}
                </>
              )}
              {cardConfiguration.showPrice && (
                <span className="d-flex pt-2">
                  <ProductModule.CoreComponents.ProductPrice
                    salePrice={salePrice}
                    listPrice={listPrice}
                    showPriceForUserType={settings?.skuShowPriceForUserType}
                  />
                </span>
              )}
              <ProductCardModule.CoreComponents.ProductCardActions
                type="dropdown"
                sku={product}
                cardConfiguration={cardConfiguration}
              />
              {cardConfiguration?.removeFromList && (
                <span className="btn-link" role="button" onClick={() => removeItemFromList()}>
                  Remove item from {cardConfiguration?.orderTemplate?.orderTemplateName}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export interface ProductCardWithDetailProps {
  product: SearchProduct;
  cardConfiguration?: CardConfiguration;
}

const ProductCardWithDetail = ({
  product,
  cardConfiguration = {
    showInput: true,
    showPrice: true,
    showSkuCode: true,
    showProductCode: false,
    showBrand: false,
    enableFavorites: false,
    buttons: [],
    removeFromList: false,
    orderTemplate: {},
  },
}: ProductCardWithDetailProps) => {
  const {
    productName: name,
    productDescription: description,
    optionGroups: options,
    defaultSku_skuCode: skuCode,
    defaultSku_skuID: skuID,
    urlTitle,
    salePrice,
    listPrice,
    settings,
    brand_brandName: brandName,
    brand_urlTitle: brandUrlTitle,
    productCode,
    skus,
  } = product;
  const {
    ProductCardModule,
    ProductModule,
    CommonModule: { Truncate },
  } = useElementContext();

  const { t } = useTranslation();
  const skuUom = options && options?.length > 0 ? options[0].optionGroupName : "";
  const productRoute = useSelector(getProductRoute);
  const brandRoute = useSelector(getBrandRoute);
  const productLink = `/${productRoute}/${urlTitle}` + (skuID?.length ? `?skuid=${skuID}` : "");
  const defaultSku = skus.find((sku) => sku.skuID === skuID) || skus[0];
  const dispatch = useDispatch();
  const removeItemFromList = () => {
    dispatch<any>(removeWishlistItem(skuID, cardConfiguration?.orderTemplate?.orderTemplateID)).then((res: any) => {
      if (res.isSuccess()) {
        toast.success("Item removed from the list successfully!");
      }
    });
  };

  return (
    <div className="card productCardWithDetail p-3 my-2">
      <div
        className="d-md-grid d-sm-flex flex-column flex-md-row align-items-center align-items-md-start"
        style={{ gridTemplateColumns: "1fr 4fr" }}
      >
        <div className="d-flex flex-column align-items-center flex-shrink-0 h-100 w-auto">
          <Link to={productLink} style={{ maxWidth: 160 }}>
            {defaultSku && <ProductCardModule.CoreControl.ProductCardImage product={defaultSku} />}
          </Link>
          {skuID && cardConfiguration.enableFavorites && (
            <ProductModule.CoreComponents.HeartButton skuID={skuID} className=" align-self-center link btn-wishlist" />
          )}
        </div>

        <div className="flex-grow-1 w-100">
          <div
            className="d-sm-flex d-md-grid flex-wrap align-content-start gap-4"
            style={{ gridTemplateColumns: "2fr 2fr" }}
          >
            <div className="col-12">
              <Link to={productLink} className="product-name">
                <h5 className="mx-1 pt-2">{name}</h5>
              </Link>
              {cardConfiguration?.showBrand && (
                <Link to={`/${brandRoute}/${brandUrlTitle}`} className="text-capitalize product-brand mx-1">
                  {brandName}
                </Link>
              )}
              <Truncate lineClamp="5">
                <div
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              </Truncate>
            </div>
            <div className="col-12">
              {cardConfiguration.showProductCode && productCode && <div>Product Code: {productCode}</div>}
              {cardConfiguration.showSkuCode && (
                <>
                  <div>
                    {t("frontend.product.sku")} {skuCode}
                  </div>
                  {skuUom !== "" && <div>UOM : {skuUom}</div>}
                </>
              )}
              {cardConfiguration.showPrice && (
                <span className="d-flex pt-2">
                  <ProductModule.CoreComponents.ProductPrice
                    salePrice={salePrice}
                    listPrice={listPrice}
                    showPriceForUserType={settings?.skuShowPriceForUserType}
                  />
                </span>
              )}
              {defaultSku && (
                <ProductCardModule.CoreComponents.ProductCardActions
                  type="dropdown"
                  sku={defaultSku}
                  cardConfiguration={cardConfiguration}
                  productConfigurator={product.productConfigurator}
                />
              )}
              {cardConfiguration?.removeFromList && (
                <span className="btn-link" role="button" onClick={() => removeItemFromList()}>
                  Remove item from {cardConfiguration?.orderTemplate?.orderTemplateName}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ProductCardWithDetail, SkuCardWithDetail };

import { ProductCardActionsModel } from "../Components/ProductCardActions/ProductCardActions.componentModel";
import { useElementContext } from "../../../contexts";
import { useCallback, useEffect, useRef, useState } from "react";

const DropdownButtons = ({
  buttonStack,
  ...rest
}: Omit<ProductCardActionsModel, "type" | "showProductModifierModal"> & { onAddToCart: () => void }) => {
  const {
    ProductCardModule,
    CommonModule: { Portal },
  } = useElementContext();
  const [button, ...buttons] = buttonStack;
  const { sku } = rest;
  const accordionRef = useRef<HTMLDivElement>(null);
  const accordionCollapseRef = useRef<HTMLDivElement>(null);
  const [withPortal, setWithPortal] = useState(false);

  // Workaround to show dropdownButton for productCard in slick slider
  const rePosition = useCallback(() => {
    if (!accordionRef.current || !accordionCollapseRef.current) return;
    const { offsetWidth, offsetHeight } = accordionRef.current;
    const { left, top } = accordionRef.current.getBoundingClientRect();
    accordionCollapseRef.current.style.left = `${left}px`;
    accordionCollapseRef.current.style.top = `${top + offsetHeight}px`;
    accordionCollapseRef.current.style.width = `${offsetWidth}px`;
  }, []);
  useEffect(() => {
    if (!accordionRef.current || !accordionRef.current.closest(".slick-track")) return;
    setWithPortal(true);
    const onScroll = () => {
      if (accordionCollapseRef.current?.classList.contains("show")) rePosition();
    };
    window.addEventListener("scroll", onScroll);
    window.addEventListener("resize", onScroll);
    let observer = new MutationObserver(() => {
      rePosition();
      if (!accordionRef.current?.closest(".slick-active")) {
        accordionCollapseRef.current?.classList.remove("show");
        const accordionBtn = accordionRef.current?.querySelector(".accordion-button");
        accordionBtn?.setAttribute("aria-expanded", "false");
        if (!accordionBtn?.classList.contains("collapsed")) accordionBtn?.classList.add("collapsed");
      }
    });
    observer.observe(accordionRef.current.closest(".slick-track")!, {
      attributes: true,
    });

    return () => {
      observer.disconnect();
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", onScroll);
    };
  }, [rePosition]);

  useEffect(() => {
    const handleGlobalClick = (event: MouseEvent) => {
      if (
        !accordionRef.current?.contains(event.target as Node) &&
        !accordionCollapseRef.current?.contains(event.target as Node)
      ) {
        accordionCollapseRef.current?.classList.remove("show");
        const accordionBtn = accordionRef.current?.querySelector(".accordion-button");
        accordionBtn?.setAttribute("aria-expanded", "false");
        accordionBtn?.classList.add("collapsed");
      }
    };
    document.addEventListener("click", handleGlobalClick);

    return () => {
      document.removeEventListener("click", handleGlobalClick);
    };
  }, []);

  return (
    <div className="accordion accordion-cart" ref={accordionRef}>
      <div className="accordion-item quote-input-box d-flex">
        <ProductCardModule.CoreControl.ProductCardButton {...rest} {...button} buttonStyle="btn-primary" />
        {buttons.length > 0 && (
          <>
            <button
              disabled={!sku?.settings.skuAllowQuoteFlag}
              className="accordion-button addToQuote_dropdownBtn collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#productCard_dropdown_${sku.skuID}`}
              aria-expanded="false"
              aria-controls={`productCard_dropdown_${sku.skuID}`}
              onClick={withPortal ? rePosition : undefined}
            >
              <span className="pe-2">
                MORE
                <br />
                OPTIONS
              </span>
            </button>
            <Portal withPortal={withPortal}>
              <div
                ref={accordionCollapseRef}
                id={`productCard_dropdown_${sku.skuID}`}
                className="accordion-collapse accordion-absolute collapse"
                aria-labelledby="cart-headingOne"
                style={withPortal ? { position: "fixed", border: "1px solid #eee", borderTop: "none", zIndex: 1 } : {}}
              >
                <div className="accordion-body">
                  {buttons.map((buttonProps: any, idx: number) => (
                    <div key={idx} className="pt-2">
                      <ProductCardModule.CoreControl.ProductCardButton
                        {...rest}
                        {...buttonProps}
                        buttonStyle="btn-link"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Portal>
          </>
        )}
      </div>
    </div>
  );
};

export { DropdownButtons };

import ContentLoader from "react-content-loader";

export interface ListLoaderProps {
  rowSize?: number;
  row?: number;
  rowGap?: number;
}

const ListLoader = ({ rowSize = 40, row = 10, rowGap = 4 }) => {
  return (
    <ContentLoader height={(rowSize + rowGap) * row} width="100%">
      {Array.apply(null, new Array(row)).map((_, i) => (
        <rect key={i} x="0" y={(rowSize + rowGap) * i} rx="4" ry="4" width="100%" height={rowSize} />
      ))}
    </ContentLoader>
  );
};

export { ListLoader };

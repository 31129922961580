import { Dispatch, Fragment, SetStateAction } from "react";
import isEqual from "fast-deep-equal";
import {
  ProductConfigurator,
  ProductConfiguratorOptions,
  ProductConfiguratorSelection,
} from "../../../interface/Product";
import { useDeepCompareEffect } from "react-use";
import { orderBy } from "lodash-es";
import { useElementContext } from "../../../contexts";

export interface ProductConfiguratorsProps {
  productConfigurator: ProductConfigurator;
  skuConfiguration: { [key: string]: string };
  setSkuConfiguration: Dispatch<SetStateAction<ProductConfiguratorsProps["skuConfiguration"]>>;
}

export interface ProductConfiguratorInput {
  configuratorOptions: ProductConfiguratorOptions;
  skuConfiguration: { [key: string]: string };
  setSkuConfiguration: Dispatch<SetStateAction<ProductConfiguratorsProps["skuConfiguration"]>>;
}

export const isSelectionDisabled = (
  selection: ProductConfiguratorSelection,
  skuConfiguration: ProductConfiguratorsProps["skuConfiguration"],
) => {
  return (
    !!selection.dependsOn.length &&
    !selection.dependsOn.every(({ groupid, selectionids }) =>
      selectionids.some((id) => skuConfiguration[groupid] === id),
    )
  );
};

export const ProductConfiguratorDropdown = ({
  configuratorOptions,
  skuConfiguration,
  setSkuConfiguration,
}: ProductConfiguratorInput) => {
  return (
    <div className="productConfigurationSelector_dropdown">
      <label className="productConfigurationSelector_inputLabel h6">
        {configuratorOptions.name}
        {configuratorOptions.requiredFlag ? <span className="text-danger">*</span> : ""}
      </label>
      <select
        className="custom-select rounded-pill"
        value={skuConfiguration[configuratorOptions.id] || ""}
        onChange={(e) => {
          setSkuConfiguration((prevState) => ({
            ...prevState,
            [configuratorOptions.id]: e.target.value,
          }));
        }}
      >
        <option value="">-- Choose Configuration Setting --</option>
        {configuratorOptions.selections.map((selection) => {
          const disabled = isSelectionDisabled(selection, skuConfiguration);

          return (
            <option key={selection.id} value={selection.id} disabled={disabled}>
              {selection.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export const ProductConfiguratorRadio = ({
  configuratorOptions,
  skuConfiguration,
  setSkuConfiguration,
}: ProductConfiguratorInput) => {
  return (
    <div className="productConfigurationSelector_radio">
      <label className="productConfigurationSelector_inputLabel h6">
        {configuratorOptions.name}
        {configuratorOptions.requiredFlag ? <span className="text-danger">*</span> : ""}
      </label>

      {configuratorOptions.selections.map((selection) => {
        const disabled = isSelectionDisabled(selection, skuConfiguration);
        return (
          <div className="form-check" key={selection.id}>
            <input
              onChange={() => {
                setSkuConfiguration((prevState) => ({ ...prevState, [configuratorOptions.id]: selection.id }));
              }}
              value={selection.id}
              className={"form-check-input"}
              type="radio"
              id={`${configuratorOptions.code}.${selection.code}`}
              name={`${configuratorOptions.code}.${selection.code}`}
              checked={skuConfiguration[configuratorOptions.id] === selection.id}
              disabled={disabled}
            />
            <label className="form-check-label" htmlFor={`${configuratorOptions.code}.${selection.code}`}>
              {selection.name}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export const ProductConfiguratorSwatch = ({
  configuratorOptions,
  skuConfiguration,
  setSkuConfiguration,
}: ProductConfiguratorInput) => {
  return (
    <div className="productConfigurationSelector_swatch">
      <label className="productConfigurationSelector_inputLabel h6">
        {configuratorOptions.name}
        {configuratorOptions.requiredFlag ? <span className="text-danger">*</span> : ""}
      </label>
      <div className="productConfigurationSelector_swatchList">
        {configuratorOptions.selections.map((selection) => {
          const disabled = isSelectionDisabled(selection, skuConfiguration);
          const checked = skuConfiguration[configuratorOptions.id] === selection.id;
          return (
            <label
              className={`productConfigurationSelector_swatchOption ${
                checked ? "productConfigurationSelector_swatchOption-checked" : ""
              } form-check`}
              key={selection.id}
              htmlFor={`${configuratorOptions.code}.${selection.code}`}
            >
              <img
                className="productConfigurationSelector_swatchImg"
                src={selection.selectionImage}
                alt={`${configuratorOptions.name} - ${selection.name}`}
              />
              <input
                onChange={() => {
                  setSkuConfiguration((prevState) => ({ ...prevState, [configuratorOptions.id]: selection.id }));
                }}
                value={selection.id}
                className={"form-check-input d-none"}
                type="radio"
                id={`${configuratorOptions.code}.${selection.code}`}
                name={`${configuratorOptions.code}.${selection.code}`}
                checked={checked}
                disabled={disabled}
              />
              <span className="form-check-label">{selection.name}</span>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export const ProductConfiguratorSelector = ({
  productConfigurator,
  skuConfiguration,
  setSkuConfiguration,
}: ProductConfiguratorsProps) => {
  const { ProductModule } = useElementContext();
  useDeepCompareEffect(() => {
    setSkuConfiguration((prevState) => {
      const newState: { [key: string]: string } = { ...prevState };
      productConfigurator.options.forEach((configuratorOptions) => {
        if (skuConfiguration[configuratorOptions.id]) {
          const selection = configuratorOptions.selections.find(
            ({ id }) => id === skuConfiguration[configuratorOptions.id],
          );
          if (!selection || isSelectionDisabled(selection, skuConfiguration)) {
            delete newState[configuratorOptions.id];
          }
        }
      });
      if (!isEqual(prevState, newState)) return newState;
      return prevState;
    });
  }, [productConfigurator, skuConfiguration, setSkuConfiguration]);

  const renderProductConfiguratorInput = (configuratorOptions: ProductConfiguratorOptions) => {
    if (configuratorOptions.inputType === "radio") {
      return (
        <ProductModule.CoreControl.ProductConfiguratorRadio
          skuConfiguration={skuConfiguration}
          configuratorOptions={configuratorOptions}
          setSkuConfiguration={setSkuConfiguration}
        />
      );
    }

    if (configuratorOptions.inputType === "swatch") {
      return (
        <ProductModule.CoreControl.ProductConfiguratorSwatch
          skuConfiguration={skuConfiguration}
          configuratorOptions={configuratorOptions}
          setSkuConfiguration={setSkuConfiguration}
        />
      );
    }
    if (configuratorOptions.inputType === "drop-down") {
      return (
        <ProductModule.CoreControl.ProductConfiguratorDropdown
          skuConfiguration={skuConfiguration}
          configuratorOptions={configuratorOptions}
          setSkuConfiguration={setSkuConfiguration}
        />
      );
    }

    // Dropdown as fallback
    return (
      <ProductModule.CoreControl.ProductConfiguratorDropdown
        skuConfiguration={skuConfiguration}
        configuratorOptions={configuratorOptions}
        setSkuConfiguration={setSkuConfiguration}
      />
    );
  };

  return (
    <div className="productConfigurationSelector">
      <div className="d-flex flex-column gap-4">
        {orderBy(productConfigurator.options, "sortOrder", "asc").map((configuratorOptions) => (
          <Fragment key={configuratorOptions.id}>
            {renderProductConfiguratorInput({
              ...configuratorOptions,
              selections: orderBy(configuratorOptions.selections, "sortOrder", "asc"),
            })}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

import { Product, Sku, ProductConfigurator } from "../../interface/Product";
import queryString from "query-string";
import { dynamicAreaLayoutEngine } from "../../services/factory/Factory_Content_LayoutEngine.class";
import { ProductImage } from "../../interface/ProductImageGallery";
import { Dispatch, SetStateAction } from "react";

import { ProductModule } from "../../modules/Product/product.moduleInterface";
import { DisplayConfig } from "../../modules/Product/product.moduleModels";
import { ModuleProps } from "../../interface/common";
import { IProductContext } from "../../contexts/ProductContext";
import { getConfigurationPrice } from "../../utils";

export interface PdpConfig {
  enableFavorites: boolean;
  showInputLabel: boolean;
  showExistingQuoteAndLists: boolean;
  input: {
    showInventory: boolean;
    quantityInput: "text" | "dropdown";
  };
  options: {
    style: "dropdown" | "tile";
  };
  price: {
    showCriteria: "Always" | "Authenticated Users" | "Never";
  };
  defaultSkuOnPageLoad: false;
  breadcrumbsType: "Product type" | "Category";
  skuLabel: string;
}

interface Helmet {
  title: string;
}

type Crumb = {
  title: string;
  urlTitle: string;
};

class PdpPageModel {
  clientCustom: any;
  isFetching: boolean = false;
  product!: Product;
  productName?: string;
  productDescription?: string;
  selectedSKu?: Sku;
  helmet?: Helmet;
  showLoginForPrice: boolean = false;
  additionalInformation?: ModuleProps<typeof ProductModule.CoreControl.ProductAdditionalInformation>;
  productPrice?: ModuleProps<typeof ProductModule.CoreComponents.ProductPrice>;
  productDetailHeading?: ModuleProps<typeof ProductModule.CoreComponents.ProductDetailHeading>;
  productDetailGallery?: ModuleProps<typeof ProductModule.CoreComponents.ProductDetailGallery>;
  favoritesButton?: ModuleProps<typeof ProductModule.CoreComponents.HeartButton>;
  skuSelector?: ModuleProps<typeof ProductModule.CoreComponents.SkuSelector>;
  skuOptions?: ModuleProps<typeof ProductModule.CoreComponents.SkuOptions>;
  productForm?: ModuleProps<typeof ProductModule.CoreComponents.ProductForm>;
  productAttributes?: ModuleProps<typeof ProductModule.CoreComponents.ProductAttributes>;
  productBundle?: ModuleProps<typeof ProductModule.CoreComponents.ProductBundle>;
  productConfiguratorSelector?: ModuleProps<typeof ProductModule.CoreControl.ProductConfiguratorSelector>;

  crumbs: Crumb[] = [];
  dynamicContentElements: any[] = [];

  constructor({
    isCmsPageLoading,
    productContext,
    productTypeBase,
    productTypeRoute,
    categoryRoute,
    dynamicContentElements,
    displayConfig,
    params,
    isAuthenticated,
    sitePurpose,
    selection,
    setSelection,
    skuConfiguration,
    setSkuConfiguration,
  }: {
    isCmsPageLoading: boolean;
    productContext: IProductContext;
    productTypeBase: string;
    productTypeRoute: string;
    categoryRoute: string;
    dynamicContentElements: any[];
    displayConfig: DisplayConfig;
    params: queryString.ParsedQuery<string>;
    isAuthenticated: boolean;
    sitePurpose: string;
    selection: { [optionGroupCode: string]: string };
    setSelection: Dispatch<SetStateAction<{ [optionGroupCode: string]: string }>>;
    skuConfiguration: { [key: string]: string };
    setSkuConfiguration: Dispatch<SetStateAction<{ [key: string]: string }>>;
  }) {
    const pageLayoutEngine = new dynamicAreaLayoutEngine();

    const { product } = productContext;

    this.product = productContext.product;

    this.productName = product.productName;
    this.productDescription = product.productDescription;
    this.helmet = { title: product.settings.productHTMLTitleString };
    this.additionalInformation = product.additionalInformation
      ? { additionalInformation: product.additionalInformation }
      : undefined;
    this.isFetching = productContext.isFetching || isCmsPageLoading;

    this.dynamicContentElements = pageLayoutEngine.processPageComponents(dynamicContentElements);
    this.productAttributes = {
      product: productContext.product,
      attributeSets: productContext.attributeSets,
    };
    this.productBundle = {
      productBundle: productContext.productBundle,
      productBundleBuildOnAccount: productContext.productBundleBuildOnAccount,
      productID: productContext.product.productID,
    };

    const { skus, optionGroups } = product;

    this.setSelectedSku(product, selection, params);
    this.setCrumbs({
      product: productContext.product,
      productTypeRoute,
      productTypeBase,
      categoryRoute,
      breadcrumbsType: displayConfig.breadcrumbsType,
    });

    this.setShowLoginForPrice({
      isAuthenticated,
      priceShowCriteria: displayConfig.priceShowCriteria,
    });
    this.setProductPrice({
      isAuthenticated,
      sku: this.selectedSKu,
      priceShowCriteria: displayConfig.priceShowCriteria,
    });
    this.setProductDetailHeading({ product, sku: this.selectedSKu, displayConfig });
    this.setFavoritesButton({
      sku: this.selectedSKu,
      enableFavorites: displayConfig.showFavoritesButton,
    });
    this.setProductDetailGallery({
      sku: this.selectedSKu,
      imageGallery: productContext.imageGallery,
    });
    this.skuOptions = {
      selection,
      setSelection,
      product,
      config: displayConfig,
    };
    this.setSkuSelector({
      skus,
      sku: this.selectedSKu,
      productOptions: optionGroups,
    });
    this.setProductForm({
      sku: this.selectedSKu,
      sitePurpose,
      displayConfig,
      skuConfiguration,
      productConfigurator: product.productConfigurator,
    });
    this.setProductConfiguratorSelector({
      productConfigurator: product.productConfigurator,
      skuConfiguration,
      setSkuConfiguration,
    });
  }

  private setSelectedSku = (
    product: Product,
    selection: { [optionGroupCode: string]: string },
    params: queryString.ParsedQuery<string>,
  ) => {
    const optionGroupPairs = Object.entries(selection).map(([key, value]) => `${key}=${value}`);
    const { skus, optionGroups } = product;
    this.selectedSKu = skus.find((sku) => {
      return (
        optionGroupPairs.filter((code) => {
          return sku?.slug.includes(code);
        }).length === optionGroups.length
      );
    });

    //check if product is of gift card type, if yes then return default sku from sku list (as it will not have options)
    if (
      product?.productType_productTypeIDPath &&
      product?.defaultSku_skuID &&
      product.productType_productTypeIDPath.includes("50cdfabbc57f7d103538d9e0e37f61e4")
    ) {
      this.selectedSKu = skus.find((sku) => sku.skuID === product.defaultSku_skuID);
    }

    if (optionGroups?.length === 0 && skus.length > 0) {
      console.log("This is a product with skus without option groups");
      if (params?.skuid) {
        this.selectedSKu = skus?.find((sku) => sku.skuID === params?.skuid);
      }
      if (!this.selectedSKu) {
        this.selectedSKu = skus?.find((sku) => sku.skuID === product.defaultSku_skuID) || skus[0];
      }
    }
  };

  private setCrumbs = (data: {
    product: Product;
    productTypeRoute: string;
    productTypeBase: string;
    categoryRoute: string;
    breadcrumbsType: DisplayConfig["breadcrumbsType"];
  }) => {
    const { product, productTypeBase, productTypeRoute, categoryRoute, breadcrumbsType } = data;
    const { breadcrumbs, categories } = product;

    if (breadcrumbsType === "Category") {
      this.crumbs = categories.map((category) => ({
        title: category.categoryName,
        urlTitle: `/${categoryRoute}/${category.urlTitle}`,
      }));
    } else {
      this.crumbs = (breadcrumbs || [])?.reduce<Crumb[]>((acc, crumb, index) => {
        if (crumb.urlTitle === productTypeBase) return acc;
        if (!acc.length) acc.push({ title: crumb.productTypeName, urlTitle: `/${productTypeRoute}/${crumb.urlTitle}` });
        else acc.push({ title: crumb.productTypeName, urlTitle: `${acc[acc.length - 1].urlTitle}/${crumb.urlTitle}` });
        return acc;
      }, []);
    }
  };

  private setShowLoginForPrice = ({
    priceShowCriteria,
    isAuthenticated,
  }: {
    priceShowCriteria: DisplayConfig["priceShowCriteria"];
    isAuthenticated: boolean;
  }) => {
    this.showLoginForPrice = !isAuthenticated && priceShowCriteria === "Authenticated Users";
  };

  private setProductPrice = ({
    sku,
    isAuthenticated,
    priceShowCriteria,
  }: {
    sku?: Sku;
    isAuthenticated: boolean;
    priceShowCriteria: DisplayConfig["priceShowCriteria"];
  }) => {
    if (!sku) return;
    if (priceShowCriteria === "Never") return;
    if (priceShowCriteria === "Authenticated Users" && !isAuthenticated) return;

    this.productPrice = {
      listPrice: sku.listPrice,
      salePrice: sku.salePrice,
      showPriceForUserType: sku.settings.skuShowPriceForUserType,
    };
  };

  private setProductDetailHeading = ({
    product,
    sku,
    displayConfig,
  }: {
    product: Product;
    sku?: Sku;
    displayConfig: DisplayConfig;
  }) => {
    this.productDetailHeading = { product, sku, skuLabel: displayConfig.skuLabel };
  };

  private setFavoritesButton = ({ sku, enableFavorites }: { sku?: Sku; enableFavorites: boolean }) => {
    this.favoritesButton = sku && enableFavorites ? { skuID: sku.sku_skuID } : undefined;
  };

  private setProductDetailGallery = ({ sku, imageGallery }: { sku?: Sku; imageGallery?: ProductImage[] }) => {
    this.productDetailGallery = imageGallery && {
      skuID: sku?.skuID,
      imageGallery,
    };
  };

  private setSkuSelector = ({ sku, skus, productOptions }: PdpPageModel["skuSelector"] & { productOptions: any[] }) => {
    if (productOptions?.length !== 0 || skus.length === 0) return;
    this.skuSelector = {
      sku,
      skus,
    };
  };

  private setProductConfiguratorSelector = ({
    productConfigurator,
    setSkuConfiguration,
    skuConfiguration,
  }: {
    productConfigurator?: ProductConfigurator;
    skuConfiguration: { [key: string]: string };
    setSkuConfiguration: Dispatch<SetStateAction<{ [key: string]: string }>>;
  }) => {
    if (!productConfigurator?.options?.length) return;

    this.productConfiguratorSelector = { productConfigurator, setSkuConfiguration, skuConfiguration };

    const configuratorPrice = getConfigurationPrice(productConfigurator, skuConfiguration);

    if (this.productPrice) {
      this.productPrice = {
        ...this.productPrice,
        listPrice: configuratorPrice,
        salePrice: configuratorPrice,
      };
    }

    if (
      productConfigurator?.options?.some(
        (configurator) => configurator.requiredFlag && !skuConfiguration[configurator.id],
      )
    ) {
      this.productForm = undefined;
    }
  };

  private setProductForm = ({
    sitePurpose,
    sku,
    displayConfig,
    skuConfiguration,
    productConfigurator,
  }: {
    sku: Sku | undefined;
    sitePurpose: string;
    displayConfig: DisplayConfig;
    skuConfiguration: { [key: string]: string };
    productConfigurator?: ProductConfigurator;
  }) => {
    if (sitePurpose === "Non-Transactional") return;
    if (!sku) return;

    this.productForm = {
      sku,
      showInputLabel: displayConfig?.showInputLabel,
      quantityInputMode: displayConfig.quantityInputMode,
      showInventory: displayConfig.showInventory,
      showExistingQuoteAndLists: displayConfig.showExistingQuoteAndLists,
      skuConfiguration:
        !!productConfigurator?.options?.length && Object.keys(skuConfiguration).length ? skuConfiguration : undefined,
    };
  };
}

export default PdpPageModel;

import { CancelTokenSource } from "axios";
import queryString from "query-string";
import { ProductFactory } from "../factories/ProductFactory";
import { ProductSearchFactory } from "../factories/ProductSearchFactory";
import { axios } from "./AxiosService";
import { SearchProduct } from "../interface/SearchProduct";

export const DEFAULT_GLOBAL_FILTER = {
  PRODUCT_TYPE: "filter_productTypes.productTypeUrlTitle",
  PRODUCT_TYPE_PARENT_PRODUCTS_ONLY: "filter_productTypeUrlTitle",
  BRAND: "filter_brandUrlTitle",
  CATEGORY: "filter_categories.categoryUrlTitle",
  FEATURED: "filter_productFeaturedFlag",
};

const serviceDomain = process.env.REACT_APP_DATASTORE_URL;

const getSearchConfig = () => ({
  productTargetConfig: window.UC?.integrations?.search.productSearchConfigConcern,
  typeaheadTargetConfig: window.UC?.integrations?.search.typeaheadSearchConfigConcern,
});

export class ProductService {
  productFactory!: typeof ProductFactory;
  productSearchFactory!: typeof ProductSearchFactory;
  constructor({ productFactory = ProductFactory, productSearchFactory = ProductSearchFactory } = {}) {
    this.productFactory = productFactory;
    this.productSearchFactory = productSearchFactory;
  }

  async getProduct(urlTitle: string, options: { source?: CancelTokenSource; processingSettings?: any } = {}) {
    try {
      let url = `${serviceDomain}/product/getProduct?urlTitle=${urlTitle}&storeTarget=${window.UC?.integrations?.datastore}`;
      if (process.env.REACT_APP_DELTA_STORE_URL) {
        url = `${process.env.REACT_APP_DELTA_STORE_URL}/public/ultracommerce/product/transform/byUrlTitle/${urlTitle}`;
      }
      const { data } = await axios(url);
      if (!data) throw new Error();
      return new this.productFactory(
        { product: data, attributeSets: data.attributeSets } as any,
        options.processingSettings,
      );
    } catch (e) {
      if (process.env.NODE_ENV !== "production")
        console.error("Error fetching product " + urlTitle + " from Ultra Commerce API", e);
      return { isError: true, message: "Error fetching product " + urlTitle + " from Ultra Commerce API" };
    }
  }

  formatSearchQuery = (searchData: any, payload: any) => {
    if (payload.hasOwnProperty("sort") && payload.sort) {
      searchData.sort = payload.sort;
    }

    Object.keys(payload).forEach((key) => {
      if (key.startsWith("facet_")) {
        let value = Array.isArray(payload[key]) ? payload[key].join() : payload[key];
        if (value !== "") {
          searchData.facets.push({ key: key.replace("facet_", ""), value });
        }
      }
      if (key.startsWith("filter_")) {
        let value = Array.isArray(payload[key]) ? payload[key].join() : payload[key];
        if (value !== "") {
          if (key === DEFAULT_GLOBAL_FILTER.PRODUCT_TYPE) {
            value.split("/").forEach((part: string) => {
              searchData.filters.push({ key: key.replace("filter_", ""), value: part });
            });
          } else {
            searchData.filters.push({ key: key.replace("filter_", ""), value });
          }
        }
      }
    });
  };

  async search(params: any, type: "product" | "sku", options: { source?: CancelTokenSource } = {}) {
    const servicePath = `/search/query/${type}`;
    const payload = { ...params };
    if (payload.keyword) {
      payload["searchTerm"] = payload.keyword;
    }

    if (payload.currentPage) {
      payload["pageFrom"] = payload.currentPage;
    }

    delete payload["keyword"];
    delete payload["currentPage"];

    if (process.env.REACT_APP_DELTA_API_URL) {
      const searchData: any = {
        facets: [],
        filters: [],
        query: payload.searchTerm,
        pageFrom: payload.pageFrom,
        route: window.location.pathname,
      };
      this.formatSearchQuery(searchData, payload);
      const { data } = await axios(`${process.env.REACT_APP_DELTA_API_URL}/api/v1/public/search`, {
        data: searchData,
        method: "POST",
        cancelToken: options.source?.token,
      });
      // hydrate products
      let fetchProducts: SearchProduct[] = await Promise.all(
        data.products.map(async (product: any) => {
          return fetch(product.productDataURL).then((resp) => resp.json());
        }),
      );
      data.products = fetchProducts;
      return new this.productSearchFactory(data);
    } else {
      const { data } = await axios(
        serviceDomain +
          servicePath +
          `?${queryString.stringify(
            { concernTarget: getSearchConfig().productTargetConfig, ...payload },
            {
              arrayFormat: "comma",
            },
          )}`,
        {
          cancelToken: options.source?.token,
        },
      );
      return new this.productSearchFactory(data);
    }
  }

  searchTypeahead(
    searchTerm: string,
    typeToSearch: "product" | "sku" | "category" | "productType",
    options: { source?: CancelTokenSource } = {},
  ) {
    if (process.env.REACT_APP_DELTA_API_URL) {
      return axios(`${process.env.REACT_APP_DELTA_API_URL}/api/v1/public/search/typeahead`, {
        data: {
          searchTerm,
        },
        withCredentials: false,
        method: "POST",
        cancelToken: options.source?.token,
      }).then((resp) => {
        return {
          ...resp,
          data: {
            ...resp.data,
            items: resp.data.items.map((item: any) => {
              if (!item.metadata.skuID) item.metadata.skuID = "na";
              return item;
            }),
          },
        };
      });
    } else {
      const servicePath = `/search/typeahead`;
      return axios(
        serviceDomain +
          servicePath +
          `?${queryString.stringify(
            { concernTarget: getSearchConfig().typeaheadTargetConfig, searchTerm, typeToSearch },
            {
              arrayFormat: "comma",
            },
          )}`,
        {
          cancelToken: options.source?.token,
        },
      );
    }
  }
}

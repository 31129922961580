import { Link } from "react-router-dom";
import { useFormatCurrency } from "../../../../hooks";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useElementContext } from "../../../../contexts";

const OrderListItem = (props) => {
  const [formatCurrency] = useFormatCurrency({});

  const { orderName, orderNumber, orderID, orderStatusType_typeName, calculatedTotal, orderType } = props;
  return (
    <tr>
      <td>
        {orderID && (
          <Link className="nav-link-style font-weight-medium font-size-sm" to={`/my-account/orders/${orderID}`}>
            {orderName?.trim() || orderNumber}
          </Link>
        )}
        <br />
      </td>
      <td>{orderStatusType_typeName}</td>
      <td>{formatCurrency(calculatedTotal)}</td>
      <td>
        {orderType === "Draft" ? (
          <Link
            className="nav-link-style font-weight-medium font-size-sm text-primary"
            to={`/my-account/draftorder/${orderID}`}
          >
            Edit
          </Link>
        ) : (
          <Link
            className="nav-link-style font-weight-medium font-size-sm text-primary"
            to={`/my-account/orders/${orderID}`}
          >
            View
          </Link>
        )}
      </td>
    </tr>
  );
};

const OverviewOrderList = ({ orders, orderType = "order" }) => {
  const {
    CommonModule: { ListLoader },
  } = useElementContext();
  const { t } = useTranslation();
  const { mostRecentCount } = useSelector((state) => state.configuration.myAccount);
  if (orders.isFetching) {
    return <ListLoader row={5} />;
  }
  return (
    <>
      {orders.data && orders.data.ordersOnAccount && orders.data.ordersOnAccount.length > 0 && (
        <>
          <div className="row rounded align-items-center justify-content-between ">
            <div className="table-responsive font-size-md">
              <table className="table table-striped table-bordered mt-3">
                <thead>
                  <tr>
                    <th>{t("frontend.account.scheduled.delivery.detail.orderNumber")}</th>
                    <th>{t("frontend.account.order.status")}</th>
                    <th> {t("frontend.account.order.total")}</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {orders.isLoaded &&
                    orders.data.ordersOnAccount
                      .filter((_, index) => index < mostRecentCount || orderType !== "order")
                      .map((order, index) => {
                        return <OrderListItem key={index} {...order} orderType={orderType} />;
                      })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OverviewOrderList;

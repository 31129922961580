import { useElementContext } from "../../../contexts";
import { Sku } from "../../../interface/Product";
import { SearchSku } from "../../../interface/SearchProduct";

interface ProductCardImageProps {
  product: Sku | SearchSku;
}

const ProductCardImage = ({ product }: ProductCardImageProps) => {
  const {
    CommonModule: { SimpleImage },
  } = useElementContext();
  const {
    imageFile,
    skuName = "",
    product_defaultSku_calculatedImageExists,
    calculatedImageExists,
    product_defaultSku_imageFile,
  } = product;

  let imageFilePath = imageFile;
  if (calculatedImageExists === true) {
    imageFilePath = imageFile;
  } else if (product_defaultSku_calculatedImageExists === true) {
    //fallback to default sku image
    imageFilePath = product_defaultSku_imageFile;
  } else {
    imageFilePath = imageFile;
  }

  return <SimpleImage className="productCardImage" src={imageFilePath} alt={skuName} type="product" />;
};

export { ProductCardImage, type ProductCardImageProps };

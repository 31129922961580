const Spinner = ({ wrapperHeight = "50vh", wrapperWidth = "100%", spinnerSize = 80 }) => {
  return (
    <div className="sk-wrapper" style={{ height: wrapperHeight, width: wrapperWidth, minHeight: wrapperHeight }}>
      <div className="sk-chase" style={{ height: spinnerSize, width: spinnerSize }}>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
      </div>
    </div>
  );
};

const OverlaySpinner = ({ wrapperHeight = "50vh", wrapperWidth = "auto", spinnerSize = 80 }) => {
  return (
    <div className="sk-overlay" style={{ height: wrapperHeight, width: wrapperWidth, minHeight: wrapperHeight }}>
      <div className="sk-chase" style={{ height: spinnerSize, width: spinnerSize }}>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
      </div>
    </div>
  );
};

export { Spinner, OverlaySpinner };

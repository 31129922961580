import { useTranslation } from "react-i18next";
import { useCheckoutUtilities, CHECK_PAYMENT_CODE } from "../../../hooks";
import { useElementContext } from "../../../contexts";
import { isStripePayment } from "./StripePayment";

const PaymentList = ({
  payments,
  onRemovePayment = () => {},
  disableInteraction = false,
  onEditDetails = () => {},
}) => {
  const {
    CommonModule: {
      GiftCardDetails,
      CCDetails,
      CheckPaymentDetails,
      TermPaymentDetails,
      ExternalPaymentDetails,
      CashPaymentDetails,
    },
  } = useElementContext();
  const { CREDIT_CARD_CODE, GIFT_CARD_CODE, TERM_PAYMENT_CODE, CASH_PAYMENT_CODE, EXTERNAL_PAYMENT_CODE } =
    useCheckoutUtilities();
  const { t } = useTranslation();
  if (payments.length === 0) return null;
  return (
    <>
      <h4 className="h4 pt-0">{t("frontend.checkout.payments")}:</h4>
      <div className="row mx-0 pt-2 ">
        {payments.map((payment) => {
          return (
            <div className="bg-lightgray rounded mb-4 col-md-4 p-3" key={payment.orderPaymentID}>
              {payment.paymentMethod.paymentMethodType === CREDIT_CARD_CODE && (
                <CCDetails hideHeading={true} creditCardPayment={payment} />
              )}
              {payment.paymentMethod.paymentMethodType === GIFT_CARD_CODE && (
                <GiftCardDetails method={payment.paymentMethod.paymentMethodType} />
              )}
              {payment.paymentMethod.paymentMethodType === TERM_PAYMENT_CODE && (
                <TermPaymentDetails hideHeading={true} termPayment={payment} />
              )}
              {payment.paymentMethod.paymentMethodType === CASH_PAYMENT_CODE && (
                <CashPaymentDetails hideHeading={true} cashPayment={payment} />
              )}
              {payment.paymentMethod.paymentMethodType === CHECK_PAYMENT_CODE && (
                <CheckPaymentDetails hideHeading={true} payment={payment} />
              )}
              {payment.paymentMethod.paymentMethodType === EXTERNAL_PAYMENT_CODE && (
                <ExternalPaymentDetails hideHeading={true} payment={payment} />
              )}
              <hr />
              <button
                className="btn btn-link px-0 me-2"
                type="button"
                disabled={disableInteraction}
                onClick={(event) => {
                  onRemovePayment({ orderPaymentID: payment.orderPaymentID });
                }}
              >
                <i className="fal fa-times-circle"></i>
                <span className="small"> {t("frontend.core.changePaymentMethod")}</span>
              </button>
              {(payment.paymentMethod.paymentMethodType === CREDIT_CARD_CODE ||
                isStripePayment(payment.paymentMethod)) &&
                !payment.accountPaymentMethod?.accountPaymentMethodID && (
                  <button
                    className="btn btn-link px-0"
                    type="button"
                    disabled={disableInteraction}
                    onClick={(event) => {
                      onEditDetails(payment);
                    }}
                  >
                    <i className="fal fa-times-circle"></i>
                    <span className="small"> {t("frontend.core.editDetails")}</span>
                  </button>
                )}
            </div>
          );
        })}
      </div>
    </>
  );
};
export { PaymentList };

import { ProductImage } from "../../../../interface/ProductImageGallery";

export interface ProductImageGalleryProps {
  imageGallery: ProductImage[];
  skuID?: string;
}

export class ProductImageGalleryModel {
  filterImages: ProductImage[] = [];

  constructor({ props: { imageGallery, skuID } }: { props: ProductImageGalleryProps }) {
    if (imageGallery.length) {
      this.filterImages = imageGallery?.filter(({ assignedSkuIDList = "", resizedImagePaths = [], type }) => {
        return (
          (type === "skuDefaultImage" ||
            type === "productAlternateImage" ||
            (skuID && assignedSkuIDList.includes(skuID))) &&
          resizedImagePaths?.at(0) &&
          !resizedImagePaths.at(0)?.includes("missingimage")
        );
      });
    }
    if (this.filterImages.length === 0) {
      this.filterImages = [{ originalPath: "", name: "", resizedImagePaths: ["", "", ""] }];
    }
  }
}

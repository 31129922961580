import { useTranslation } from "react-i18next";
import { useState, useEffect, useMemo, useCallback } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AddProductToQuoteModalProps, AddProductToQuoteModalModel } from "./AddProductToQuoteModal.componentModel";
import { addMultipleItemsToOrder, addToOrder, setAllCartOrQuotes } from "../../../../actions";
import { formatSkuConfigurationPayload, getErrorMessage, isAuthenticated } from "../../../../utils";
import { axios, SlatwalApiService, getSdkURL } from "../../../../services";

export const useComponentData = (props: AddProductToQuoteModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [existingQuotes, setExistingQuotes] = useState<{ value: string; key: string }[]>([]);
  const [existingQuoteVal, setExistingQuoteVal] = useState<string>("");
  const [isLoadingForExisitingQuote, setLoadingForExistingQuote] = useState(false);
  const [quoteName, setQuoteName] = useState("");

  const componentData = useMemo(
    () =>
      new AddProductToQuoteModalModel({
        props,
        isAuthenticated: !!isAuthenticated(),
        isLoading,
        isLoadingForExisitingQuote,
        existingQuotes,
        existingQuoteVal,
        quoteName,
      }),
    [existingQuoteVal, existingQuotes, isLoading, isLoadingForExisitingQuote, props, quoteName],
  );

  useEffect(() => {
    let didCancel = false;
    if (componentData.isAuthenticated && (props.skuID || props.skuIDs)) {
      SlatwalApiService.quotes.list({}).then((response: any) => {
        if (response.isSuccess() && !didCancel && response.success().quotesOnAccount?.ordersOnAccount) {
          const quotesOnAccount = response.success().quotesOnAccount;
          setExistingQuotes(
            quotesOnAccount?.ordersOnAccount
              ?.filter((quote: any) => quote.orderStatusType_typeCode === "qstDraft")
              .map(({ quoteName, orderID }: any) => ({
                key: quoteName,
                value: orderID,
              })),
          );
          setExistingQuoteVal(
            quotesOnAccount?.ordersOnAccount?.find((quote: any) => quote.orderStatusType_typeCode === "qstDraft")?.[
              "orderID"
            ],
          );
        } else {
          setExistingQuotes([]);
        }
      });
    }

    return () => {
      didCancel = true;
    };
  }, [componentData.isAuthenticated, props.skuID, props.skuIDs]);

  const addToExistingQuote = useCallback(() => {
    if (!!existingQuoteVal) setExistingQuoteVal(existingQuoteVal);
    if (!existingQuoteVal) return null;
    setLoadingForExistingQuote(true);
    const payload = props?.skuID
      ? {
          skuID: componentData.skuID,
          orderID: existingQuoteVal,
          quantity: componentData.quantity,
          ...formatSkuConfigurationPayload(props.skuConfiguration),
        }
      : {
          skuIDs: componentData.skuIDs,
          orderID: existingQuoteVal,
          quantities: componentData.quantities,
        };
    if (props.skuID) {
      dispatch(addToOrder({ params: payload, returnQuote: true }) as any).then((response: any) => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) {
          toast.error(getErrorMessage(response.success().errors));
        } else {
          if (response.isSuccess()) {
            dispatch<any>(setAllCartOrQuotes());
            setExistingQuoteVal(existingQuotes?.at(0)?.value || "");
            props.setQuoteModal(false);
            setLoadingForExistingQuote(false);
            toast.success(t("frontend.quote.exisiting.successMessage"));
          }
        }
      });
    }
    if (props.skuIDs) {
      dispatch(addMultipleItemsToOrder({ params: payload, returnQuote: true }) as any).then((response: any) => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) {
          toast.error(getErrorMessage(response.success().errors));
        } else {
          if (response.isSuccess()) {
            setExistingQuoteVal(existingQuotes?.at(0)?.value || "");
            props.setQuoteModal(false);
            setLoadingForExistingQuote(false);
            navigate("/my-account/quotes/" + existingQuoteVal);
            toast.success(t("frontend.quote.items.exisiting.successMessage"));
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [
    componentData.quantity,
    componentData.skuID,
    componentData.skuIDs,
    componentData.quantities,
    dispatch,
    existingQuoteVal,
    existingQuotes,
    props,
    navigate,
    t,
  ]);

  const addToNewQuote = useCallback(() => {
    if (!quoteName) return null;
    setLoading(true);
    if (componentData.skuID) {
      dispatch(
        addToOrder({
          params: {
            quoteName,
            skuID: componentData.skuID,
            quantity: componentData.quantity,
            ...formatSkuConfigurationPayload(props.skuConfiguration),
          },
          returnQuote: true,
          isQuote: true,
        }) as any,
      ).then((response: any) => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) {
          toast.error(getErrorMessage(response.success().errors));
        } else {
          if (response.isSuccess()) {
            setLoading(false);
            props.setQuoteModal(false);
            toast.success(t("frontend.quote.create.successMessage"));
            navigate("/my-account/quotes");
          }
        }
      });
    } else if (componentData.skuIDs) {
      dispatch(
        addMultipleItemsToOrder({
          params: {
            quoteName,
            skuIDs: componentData.skuIDs,
            quantities: componentData.quantities,
          },
          returnQuote: true,
          isQuote: true,
        }) as any,
      ).then((response: any) => {
        if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) {
          toast.error(getErrorMessage(response.success().errors));
        } else {
          if (response.isSuccess()) {
            setLoading(false);
            props.setQuoteModal(false);
            navigate("/my-account/quotes");
            toast.success(t("frontend.quote.items.create.successMessage"));
          }
        }
      });
    } else {
      axios({
        method: "POST",
        url: `${getSdkURL()}api/scope/createQuote`,
        data: { quoteName },
      }).then((response: any) => {
        setLoading(false);
        if (response?.status === 200 && response?.data?.failureActions.length === 0) {
          props.setQuoteModal(false);
          navigate("/my-account/quotes");
          toast.success(t("frontend.quote.create.successMessage"));
        } else toast.error(response?.data?.failureActions);
      });
    }
  }, [
    componentData.quantity,
    componentData.skuID,
    componentData.skuIDs,
    componentData.quantities,
    dispatch,
    navigate,
    props,
    quoteName,
    t,
  ]);

  return {
    componentData,
    componentService: {
      setQuoteModal: props.setQuoteModal,
      setExistingQuoteVal,
      addToExistingQuote,
      addToNewQuote,
      setQuoteName,
    },
  };
};

import { ProductCardConfiguration, SearchConfig, SkuCardConfiguration } from "../productListing.moduleModels";
import { SearchProduct, SearchSku } from "../../../interface/SearchProduct";
import { useElementContext } from "../../../contexts";

export interface ListingListViewProps {
  cardDisplayConfigurations: {
    skuCardConfiguration: SkuCardConfiguration;
    productCardConfiguration: ProductCardConfiguration;
  };
  isFetching: boolean;
  pageRecords: (SearchProduct | SearchSku)[];
  config: SearchConfig;
}

const ListingListView = ({ cardDisplayConfigurations, isFetching, pageRecords, config }: ListingListViewProps) => {
  const {
    ProductListingModule,
    ProductCardModule,
    CommonModule: { ListLoader },
  } = useElementContext();
  let cardConfig = cardDisplayConfigurations.skuCardConfiguration;

  if (config?.params?.productsListingFlag) {
    cardConfig = cardDisplayConfigurations.productCardConfiguration;
  }

  if (isFetching) {
    if (!["INFINITE_SCROLL_BUTTON", "INFINITE_SCROLL_AUTO"].includes(config.pagination) || pageRecords.length === 0)
      return <ListLoader rowSize={180} rowGap={20} row={4} />;
  }

  if (pageRecords.length === 0)
    return <ProductListingModule.CoreControl.NoProductFound noProductFoundLink={config.noProductFoundLink} />;

  return (
    <div className="product-list">
      {pageRecords?.map((product, index) => {
        return config?.params?.productsListingFlag ? (
          <ProductCardModule.CoreControl.ProductCardWithDetail
            key={index}
            cardConfiguration={cardConfig}
            product={product as SearchProduct}
          />
        ) : (
          <ProductCardModule.CoreControl.SkuCardWithDetail
            key={index}
            cardConfiguration={cardConfig}
            product={product as SearchSku}
          />
        );
      })}
    </div>
  );
};
export { ListingListView };

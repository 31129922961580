import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useProductType } from "../../hooks";
import { useContentContext } from "../../contexts/ContentContext";
import { useElementContext } from "../../contexts/ElementContext";
import { DEFAULT_GLOBAL_FILTER } from "../../services";

const ProductType = () => {
  const {
    ProductListingModule,
    ProductModule,
    PageModule: { DynamicPage },
    CommonModule: { PageContentRow, ListingBanner },
  } = useElementContext();
  const { id } = useParams();

  const { productTypeData, isFetching, isError, errorMessage, crumbCalculator } = useProductType();
  const pageData = useContentContext();

  const {
    productTypeSearchListing,
    productListingSettings,
    rangeTilesSettings,
    rangeHeaderStyle = "compact",
    rangeMetaData = {},
  } = pageData.templateData || {};
  if (!productTypeSearchListing) return <DynamicPage />;

  const { setting: { productHTMLTitleString } = {} } = productTypeData || {};

  const title = pageData?.title || productTypeData?.productTypeName;
  const pageLogo = pageData?.pageLogo || productTypeData?.imageFile;
  const description = pageData?.description || productTypeData?.productTypeDescription;
  return (
    <DynamicPage>
      {({ renderDynamicContent }) => (
        <>
          {productHTMLTitleString && <Helmet title={productHTMLTitleString} />}
          <PageContentRow>
            <ListingBanner
              crumbs={crumbCalculator()}
              heading={title}
              images={[pageLogo]}
              description={description}
              type="productType"
              isCollapsible={rangeHeaderStyle === "compact"}
            />
          </PageContentRow>

          {isError && (
            <div className="container bg-light box-shadow-lg rounded-lg p-5">
              <div className="row">
                <div className="alert alert-info" role="alert">
                  {errorMessage}
                </div>
              </div>
            </div>
          )}

          {renderDynamicContent()}

          {rangeTilesSettings !== "hideRangeTiles" && (
            <ProductModule.CoreComponents.ProductTypeList
              showOnlyDirectChild={rangeTilesSettings === "showOnlyDirectChild"}
              isFetching={isFetching}
              data={productTypeData}
              rangeMetaData={rangeMetaData}
            />
          )}

          {!isFetching &&
            (productListingSettings !== "hideProductListing" || productTypeData.childProductTypes?.length === 0) && (
              <ProductListingModule.CoreComponents.SearchListing
                key={productTypeData.productTypeUrlTitlePath?.split("/").slice(1).join("/") || id}
                hide="productType"
                initPreFilter={{
                  [productListingSettings === "showOnlyParentProducts"
                    ? DEFAULT_GLOBAL_FILTER.PRODUCT_TYPE_PARENT_PRODUCTS_ONLY
                    : DEFAULT_GLOBAL_FILTER.PRODUCT_TYPE]:
                    productTypeData.productTypeUrlTitlePath?.split("/").slice(1).join("/") || id,
                }}
                {...productTypeSearchListing}
              />
            )}
        </>
      )}
    </DynamicPage>
  );
};

export default ProductType;

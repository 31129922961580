import { useTranslation } from "react-i18next";

import { useFormatCurrency } from "../../../hooks";
import { useElementContext } from "../../../contexts";
import { Dispatch, SetStateAction } from "react";
import { OrderItem } from "../../../interface/Cart";

export interface MiniCartItemProps {
  cartItem: {
    id: string;
    title: string;
    items: OrderItem[];
    totalItems: number;
    subTotal: number;
    pathLabel: string;
    pathname: string;
    isFetching: boolean;
    isRemovingItem: boolean;
    cartAction: {
      removeItem?: (productItem: any) => void;
      setActiveCart?: () => void;
      getPrice?: (productItem: any) => any;
    };
    isActive: boolean;
    accordionItemClass?: string;
    buttons: {
      cta_target: "_self" | "_target";
      cta_title: string;
      cta_url: string;
      id: number;
      btn_style?: string;
    }[];
  };
  isSingleCart: boolean;
  setMiniCartOpen: Dispatch<SetStateAction<boolean>>;
}

export const MiniCartItem = ({ cartItem, isSingleCart, setMiniCartOpen }: MiniCartItemProps) => {
  const { t } = useTranslation();
  const [formatCurrency] = useFormatCurrency({});
  const {
    CommonModule: { MiniCartAccordionItem, MiniCartProductItem, ExternalLink },
  } = useElementContext();

  const {
    id,
    accordionItemClass,
    title,
    items,
    totalItems,
    subTotal,
    pathLabel,
    pathname,
    cartAction,
    isRemovingItem,
    isActive,
    buttons,
  } = cartItem;
  const productItems = items.filter((item) => item.parentOrderItemID === "");
  return (
    <MiniCartAccordionItem
      title={title}
      className={accordionItemClass}
      id={id}
      isActive={isActive}
      isSingleCart={isSingleCart}
    >
      <div className="d-flex justify-content-between py-2">
        <span className="fw-bold ">
          {totalItems} {t("frontend.home.items")}
        </span>
        <ExternalLink
          linkUrl={pathname}
          onClick={(e) => {
            setMiniCartOpen((prevState) => !prevState);
          }}
          className="fw-bold link"
        >
          {pathLabel}
        </ExternalLink>
      </div>
      {items.length > 0 && (
        <>
          <div className="miniCartItemsContainer">
            {productItems.map((productItem) => {
              return (
                <MiniCartProductItem
                  key={productItem.orderItemID}
                  productItems={productItems}
                  productItem={productItem}
                  cartAction={cartAction}
                  setMiniCartOpen={setMiniCartOpen}
                />
              );
            })}
          </div>
          <div className="d-flex justify-content-center border-top border-bottom py-2 mb-4">
            <label className="text-muted">{t("frontend.home.subtotal")}: </label>{" "}
            <span className="fw-bold">&nbsp;{formatCurrency(subTotal)}</span>
          </div>
          {productItems.filter((item) => item.quoteOnly).length > 0 && (
            <div className="text-danger m-2">
              Your cart has some quote only items, Please review them before checkout.
            </div>
          )}
          {isRemovingItem && <div className="alert alert-info m-2 small">{t("frontend.cart.removingCartItem")}</div>}
          <div className="mt-auto d-flex flex-column gap-2">
            {buttons.map((button, index) => (
              <ExternalLink
                key={index}
                linkUrl={button.cta_url}
                className={`btn ${button.btn_style}`}
                target={button.cta_target}
                onClick={() => {
                  setMiniCartOpen(false);
                }}
              >
                {button.cta_title}
              </ExternalLink>
            ))}
          </div>
        </>
      )}
      {!totalItems && <div className="alert alert-secondary m-2 small">{t("frontend.cart.empty_cart")}</div>}
    </MiniCartAccordionItem>
  );
};

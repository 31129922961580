import { Fragment, useEffect, useMemo, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { chunk, sortBy } from "lodash-es";
import { useSelector } from "react-redux";
import { SearchConfig } from "../productListing.moduleModels";
import { getProductTypeRoute } from "../../../selectors/configurationSelectors";
import { PotentialFilters } from "../../../interface/SearchProduct";
import { processQueryParameters } from "../../../utils";
import { useElementContext } from "../../../contexts";

export const useListingTopBar = ({
  filtering,
  searchConfig,
  updateAttribute,
}: {
  filtering: PotentialFilters;
  searchConfig: SearchConfig;
  updateAttribute: (data: { name: string; filterName: string }) => void;
}): ListingTopbarProps | null => {
  const loc = useLocation();
  const params = processQueryParameters(loc.search);
  const { listingTopBar } = searchConfig;
  if (!listingTopBar || listingTopBar.type === "Hidden") return null;

  if (listingTopBar.type === "Product Type") {
    if (!filtering["productTypes.productTypeUrlTitlePath"]) return null;

    // URI decode and sort by level
    let urlTitlePaths: string[] = sortBy(
      filtering["productTypes.productTypeUrlTitlePath"].options.map((option) => decodeURIComponent(option.slug)) || [],
      [(a: string) => a.split("/").length, (a) => a],
    );

    //Filter down by selected facets
    if (params["facet_productTypes.productTypeUrlTitlePath"]) {
      let selectedFacets: string[] = params["facet_productTypes.productTypeUrlTitlePath"] as string[];
      if (!Array.isArray(selectedFacets)) selectedFacets = [selectedFacets];
      selectedFacets = selectedFacets
        .map((facets) => decodeURIComponent(facets as string))
        .filter(
          (facet, _i, self) => !self.some((selfFacet) => selfFacet.startsWith(facet) && selfFacet !== facet),
        ) as string[];
      urlTitlePaths = urlTitlePaths.filter((urlTitle) =>
        selectedFacets.some((selectedFacet) => urlTitle.startsWith(selectedFacet) && urlTitle !== selectedFacet),
      );
    }

    switch (listingTopBar?.displayType) {
      case "All":
        break;
      case "Parent":
        while (urlTitlePaths.length > 1 && urlTitlePaths.every((urlTitle, _i, self) => urlTitle.startsWith(self[0]))) {
          urlTitlePaths.shift();
        }
        urlTitlePaths = urlTitlePaths.filter(
          (child) => !urlTitlePaths.some((urlTitlePath) => urlTitlePath !== child && child.startsWith(urlTitlePath)),
        );
        break;
      case "Children":
        urlTitlePaths = urlTitlePaths.filter(
          (child) => !urlTitlePaths.some((urlTitlePath) => urlTitlePath !== child && urlTitlePath.startsWith(child)),
        );
        break;
    }
    if (urlTitlePaths.length < listingTopBar.showWithRecordCount) return null;
    return {
      listingTopBar,
      items: urlTitlePaths.map((urlTitle) => ({ slug: urlTitle })),
      updateAttribute,
    };
  }

  return null;
};

export interface ListingTopbarProps {
  listingTopBar: SearchConfig["listingTopBar"];
  items: { slug: string }[];
  updateAttribute: (data: { name: string; filterName: string; selectType: "multi" | "single" }) => void;
}

export const ListingTopbar = (props: ListingTopbarProps) => {
  const { ProductListingModule } = useElementContext();
  if (props.listingTopBar?.type === "Product Type")
    return <ProductListingModule.CoreControl.ProductTypeListingTopbar {...props} />;
  return null;
};

export const ProductTypeListingTopbar = ({ listingTopBar, items, updateAttribute }: ListingTopbarProps) => {
  const {
    CommonModule: { ListLoader, SWImage },
  } = useElementContext();
  const productTypeRoute = useSelector(getProductTypeRoute);
  const [productTypeData, setProductTypeData] = useState<{ [key: string]: any }>({});
  const [showCount, setShowCount] = useState(listingTopBar?.pageSize || 8);
  const [invalidItems, setInvalidItems] = useState<string[]>([]);

  const itemToShow = useMemo(() => {
    return items.filter((item) => !invalidItems.includes(item.slug));
  }, [items, invalidItems]);

  useEffect(() => {
    const itemToFetch = Array.from(
      new Set(
        itemToShow
          .slice(0, showCount)
          .filter((item) => !productTypeData[item.slug])
          .map((item) => item.slug.split("/").slice(0, -1).join("/")),
      ),
    );
    Promise.all(
      itemToFetch.map((slug) =>
        fetch(
          `${process.env.REACT_APP_DATASTORE_URL}/public/ultracommerce/product-type/transform/byUrlTitle/merchandise${
            slug ? `/${slug}` : ""
          }`,
        )
          .then((response) => response.json())
          .catch(() => ({ children: [] })),
      ),
    ).then(async (res) => {
      const newProductTypeData = res.reduce((acc, cur) => {
        cur.children.forEach((child: any) => {
          acc[child.productTypeUrlTitlePath.replace("merchandise/", "")] = child;
        });
        return acc;
      }, {});
      await Promise.all(
        itemToShow.slice(0, showCount).map(async (item) => {
          if (!newProductTypeData[item.slug])
            newProductTypeData[item.slug] = await fetch(
              `${process.env.REACT_APP_DATASTORE_URL}/public/ultracommerce/product-type/transform/byUrlTitle/merchandise/${item.slug}`,
            )
              .then((res) => res.json())
              .catch(() => {
                setInvalidItems((prev) => [...prev, item.slug]);
              });
        }),
      );

      setProductTypeData((prevState) => ({ ...prevState, ...newProductTypeData }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemToShow, showCount]);

  if (!listingTopBar) return null;

  return (
    <div className="listingTopbar">
      <div className="listingTopbar_list">
        {chunk(itemToShow, listingTopBar.pageSize).map((itemsChunk, i) => {
          if (showCount < (i + 1) * listingTopBar.pageSize) return null;
          if (itemsChunk.some((item) => !Object.hasOwn(productTypeData, item.slug))) {
            return <ListLoader row={1} rowSize={150} key={i} />;
          }
          return (
            <Fragment key={i}>
              {itemsChunk.map((item) => {
                const productType = productTypeData[item.slug];
                if (!productType) return null;
                const productTypeNamePath = productType.productTypeNamePath.replace("Merchandise > ", "");
                const productTypeUrlTitlePath = productType.productTypeUrlTitlePath.replace("merchandise/", "");
                return (
                  <Link
                    key={productType.productTypeID}
                    to={`/${productTypeRoute}/${productType.productTypeUrlTitlePath.replace("merchandise/", "")}`}
                    onClick={(e) => {
                      if (listingTopBar?.actionType === "Facets") {
                        e.preventDefault();
                        updateAttribute({
                          filterName: "facet_productTypes.productTypeUrlTitlePath",
                          name: encodeURIComponent(productTypeUrlTitlePath),
                          selectType: "single",
                        });
                      }
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    className="listingTopbar_card"
                  >
                    <SWImage
                      className="product-image listingTopbar_cardImage"
                      src={productType.imageFile}
                      alt={productTypeNamePath}
                      type="productType"
                    />
                    <div>
                      <h5 className="listingTopbar_cardTitle">{productTypeNamePath}</h5>
                    </div>
                  </Link>
                );
              })}
            </Fragment>
          );
        })}
      </div>
      {listingTopBar.pageSize < itemToShow.length && (
        <div
          onClick={() =>
            showCount < itemToShow.length
              ? setShowCount((prev) => prev + listingTopBar.pageSize)
              : setShowCount(listingTopBar.pageSize)
          }
          className="text-center mx-auto"
        >
          <button className="btn btn-sm btn-primary">{showCount < itemToShow.length ? `See more` : "See Less"}</button>
        </div>
      )}
    </div>
  );
};

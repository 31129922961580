import { useEffect, useRef } from "react";
import { useElementContext } from "../../../contexts";
import { SearchConfig } from "../productListing.moduleModels";

export interface InfiniteScrollAutoProps {
  isFetching: boolean;
  recordsCount: number;
  currentPage: string;
  totalPages: number;
  totalCount: number;
  setPage: (page: number) => void;
  searchConfig: SearchConfig;
}

const InfiniteScrollAuto = ({
  isFetching,
  recordsCount,
  currentPage = "1",
  totalPages,
  setPage,
  totalCount,
  searchConfig,
}: InfiniteScrollAutoProps) => {
  const {
    CommonModule: { Spinner },
  } = useElementContext();

  const loadMoreRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isFetching || recordsCount >= totalCount) return;
    // Initialize IntersectionObserver when component mounts
    const observer = new IntersectionObserver(
      (entities) => {
        const target = entities[0];
        if (target.isIntersecting) {
          setPage(parseInt(currentPage, 10) + 1);
        }
      },
      {
        threshold: 0.5,
      },
    );

    // Attach observer to product container element
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      // Remove observer when component unmounts
      if (observer) {
        observer.disconnect();
      }
    };
  }, [recordsCount, totalCount, isFetching, setPage, currentPage]);

  if (!recordsCount) return null;

  return (
    <div ref={loadMoreRef} className="searchListing_infiniteScrollAuto_container">
      {isFetching && <Spinner wrapperHeight="auto" spinnerSize={50} />}
      <div className="searchListing_InfiniteScrollAuto_recordCounts">
        Showing {recordsCount} of {totalCount} products
      </div>
    </div>
  );
};

export { InfiniteScrollAuto };

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ProductTypeListModel } from "./ProductTypeList.componentModel";
import { getProductTypeRoute } from "../../../../selectors";
import { useElementContext } from "../../../../contexts";
import { sortProductTypes } from "../../../../utils";

const ProductTypeListView = ({
  componentData: {
    childProductTypes,
    showOnlyDirectChild = false,
    isFetching = true,
    customStyleClasses,
    rangeMetaData,
  },
}: {
  componentData: ProductTypeListModel;
}) => {
  const productTypeRoute = useSelector(getProductTypeRoute);
  const {
    CommonModule: { SWImage, Spinner },
  } = useElementContext();

  if (isFetching)
    return (
      <div className={`container bg-light box-shadow-lg rounded-lg p-5 ${customStyleClasses}`}>
        <Spinner />
      </div>
    );

  if (!childProductTypes.length) return null;

  return (
    <div className={`container pb-4 pb-sm-5 ${customStyleClasses}`}>
      <div className="row">
        {sortProductTypes(childProductTypes, rangeMetaData).map(
          ({ productTypeID, productTypeName, imageFile, urlTitle, childProductTypes }: any) => {
            let customImagePath = "";
            let imageFileName = "";
            if (imageFile !== "" && !imageFile.startsWith("http")) {
              imageFileName = imageFile.split("/").reverse()?.at(0);
              customImagePath = imageFile.split("/").slice(0, -1).join("/") + "/";
            } else {
              imageFileName = imageFile.includes("-thumb.")
                ? imageFile
                : imageFile.substring(0, imageFile.lastIndexOf(".")) +
                  "-thumb" +
                  imageFile.substring(imageFile.lastIndexOf("."));
            }

            const rangeData = rangeMetaData[urlTitle];
            productTypeName = rangeData?.rangeName || productTypeName;
            imageFileName = rangeData?.rangeLogo || imageFileName;

            return (
              <div className="childProductType col-lg-5-cols col-md-4 col-6 mb-3" key={productTypeID}>
                <div className="card border-0">
                  <Link
                    to={`/${productTypeRoute}/${urlTitle}`}
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      })
                    }
                    className="d-block overflow-hidden rounded-lg"
                  >
                    <SWImage
                      className="productImage d-block w-100"
                      customPath={customImagePath}
                      src={imageFileName}
                      alt={productTypeName}
                      type="productType"
                    />
                  </Link>
                  <div className="card-body py-0 mb-2">
                    <h4 className="productType-title text-center">
                      <Link
                        to={`/${productTypeRoute}/${urlTitle}`}
                        className="link-button"
                        onClick={() =>
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          })
                        }
                      >
                        {productTypeName}
                      </Link>
                    </h4>
                    {!showOnlyDirectChild && (
                      <ul className="list-unstyled font-size-sm mb-0">
                        {childProductTypes &&
                          childProductTypes.length > 0 &&
                          sortProductTypes(childProductTypes, rangeMetaData).map(
                            ({ productTypeID, productTypeName, urlTitle }: any) => {
                              const rangeData = rangeMetaData[urlTitle];
                              productTypeName = rangeData?.rangeName || productTypeName;
                              return (
                                <li className="d-flex align-items-center justify-content-between" key={productTypeID}>
                                  <Link
                                    to={`/${productTypeRoute}/${urlTitle}`}
                                    className="link-button nav-link-style d-flex align-items-center justify-content-between text-left"
                                  >
                                    <i className="bi bi-chevron-circle-right pr-2"></i>
                                    {productTypeName}
                                  </Link>
                                </li>
                              );
                            },
                          )}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            );
          },
        )}
      </div>
    </div>
  );
};
export { ProductTypeListView };

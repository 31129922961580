import { useTranslation } from "react-i18next";
import { useState, useEffect, useMemo } from "react";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { createListAndAddItem, addSkuToWishList } from "../../../../actions";

import { AddProductToListModalModel, AddProductToListModalProps } from "./AddProductToListModal.componentModel";
import { getErrorMessage, isAuthenticated } from "../../../../utils";

export const useComponentData = (props: AddProductToListModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { lists } = useSelector((state: any) => state.userReducer?.wishList);
  const [isLoading, setLoading] = useState(false);
  const [existingList, setExistingList] = useState<{ key: string; value: string }[]>([]);
  const [existingListVal, setExistingListVal] = useState<string>("");
  const [isLoadingForExisitingList, setLoadingForExistingList] = useState(false);
  const [listName, setListName] = useState("");

  useEffect(() => {
    if (lists) {
      let orderTemplates = lists.filter((list: any) => list.name !== " ");
      setExistingList(orderTemplates.map(({ name, value }: any) => ({ key: name, value: value })));
      setExistingListVal(orderTemplates?.at(0)?.value);
    } else {
      setExistingList([]);
    }
  }, [lists]);

  const componentData = useMemo(
    () =>
      new AddProductToListModalModel({
        existingList,
        existingListVal,
        isAuthenticated: !!isAuthenticated(),
        isLoading,
        isLoadingForExisitingList,
        listName,
      }),
    [existingList, existingListVal, isLoading, isLoadingForExisitingList, listName],
  );

  const onAddToList = () => {
    if (!!existingListVal) setExistingListVal(existingListVal);
    if (!existingListVal) return null;
    setLoadingForExistingList(true);
    dispatch(addSkuToWishList(props.sku.skuID, existingListVal as any) as any).then((response: any) => {
      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) {
        toast.error(getErrorMessage(response.success().errors));
      } else {
        if (response.isSuccess()) {
          setExistingListVal(existingList?.at(0)?.value || "");
          props.setListModal(false);
          setLoadingForExistingList(false);
          toast.success(t("frontend.list.addedToExistingList"));
        }
      }
    });
  };

  const onCreateList = () => {
    if (!listName) return null;
    setLoading(true);
    dispatch(createListAndAddItem(props.sku.skuID, listName) as any).then((response: any) => {
      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length) {
        toast.error(getErrorMessage(response.success().errors));
      } else {
        if (response.isSuccess()) {
          setLoading(false);
          props.setListModal(false);
          toast.success(`${t("frontend.list.addedToList")} - ${listName}`);
        }
      }
    });
  };

  return {
    componentData,
    componentService: {
      setListModal: props.setListModal,
      setExistingList,
      setExistingListVal,
      setListName,
      onAddToList,
      onCreateList,
    },
  };
};

import { ProductCardConfiguration, SearchConfig, SkuCardConfiguration } from "../productListing.moduleModels";
import { useElementContext } from "../../../contexts/ElementContext";
import { SearchProduct, SearchSku } from "../../../interface/SearchProduct";

export interface ListingGridViewProps {
  cardDisplayConfigurations: {
    skuCardConfiguration: SkuCardConfiguration;
    productCardConfiguration: ProductCardConfiguration;
  };
  isFetching: boolean;
  pageRecords: SearchProduct[] | SearchSku[];
  config: SearchConfig;
}

const ListingGridView = ({ cardDisplayConfigurations, isFetching, pageRecords, config }: ListingGridViewProps) => {
  const {
    ProductCardModule,
    ProductListingModule,
    CommonModule: { ListingGridLoader },
  } = useElementContext();
  if (isFetching) {
    if (!["INFINITE_SCROLL_BUTTON", "INFINITE_SCROLL_AUTO"].includes(config.pagination) || pageRecords.length === 0)
      return <ListingGridLoader />;
  }

  if (pageRecords.length === 0)
    return <ProductListingModule.CoreControl.NoProductFound noProductFoundLink={config.noProductFoundLink} />;

  return (
    <div className="product-grid">
      {pageRecords?.map((product, index) => {
        return (
          <div key={index} className="mb-4">
            {config?.params?.productsListingFlag ? (
              <ProductCardModule.CoreControl.ProductCard
                cardConfiguration={cardDisplayConfigurations.productCardConfiguration}
                {...(product as SearchProduct)}
              />
            ) : (
              <ProductCardModule.CoreControl.SkuCard
                cardConfiguration={cardDisplayConfigurations.skuCardConfiguration}
                {...(product as SearchSku)}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
export { ListingGridView };

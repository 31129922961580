import { SearchProduct, PotentialFilters, SortOption } from "../../../../interface/SearchProduct";
import { ListingTopbarProps } from "../../Controls/ListingTopbar";
import { SearchConfig, SkuCardConfiguration, ProductCardConfiguration } from "../../productListing.moduleModels";

export interface SearchListingProps {
  initPreFilter: { [key: string]: string };
  hide: string;
  showFilterSidebar: boolean;
  listingConfig: any;
  skuCardConfiguration: any;
  productCardConfiguration: any;
  customStyleClasses?: string;
}

export class SearchListingModel {
  showForcedPreFilters: boolean = false;
  showFilterSidebar: boolean = false;
  searchConfig: SearchConfig;
  searchListingData: {
    records: SearchProduct[];
    isFetching: boolean;
    potentialFilters: PotentialFilters;
    sortBy: SortOption[];
    total: number;
    totalPages: number;
    setSort: (orderBy: any) => void;
    updateAttribute: (attribute: any) => void;
    setPage: (pageNumber: any) => void;
    setKeyword: (keyword: any) => void;
    params: any;
    searchMeta: never[];
  };
  cardDisplayConfigurations: {
    skuCardConfiguration: SkuCardConfiguration;
    productCardConfiguration: ProductCardConfiguration;
  };
  listingTopbar: ListingTopbarProps | null = null;
  viewMode: any;
  hide: string;
  customStyleClasses?: string;

  constructor({
    props,
    searchConfig,
    searchListingData,
    listingTopbar,
    viewMode,
  }: {
    props: SearchListingProps;
    searchConfig: SearchConfig;
    searchListingData: any;
    listingTopbar: ListingTopbarProps | null;
    viewMode: any;
  }) {
    this.searchConfig = searchConfig;
    this.searchListingData = searchListingData;
    this.viewMode = viewMode;
    this.listingTopbar = listingTopbar;
    this.cardDisplayConfigurations = {
      skuCardConfiguration: JSON.parse(props?.skuCardConfiguration),
      productCardConfiguration: JSON.parse(props?.productCardConfiguration),
    };
    this.showFilterSidebar = props.showFilterSidebar ?? true;
    this.hide = props.hide || "";

    this.showForcedPreFilters = !!searchConfig?.requiredPreFilters?.find(
      (filter: string) => !searchListingData?.params[`facet_${filter}`],
    );
    this.customStyleClasses = props.customStyleClasses;
  }
}

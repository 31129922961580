import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { applyPromoCode } from "../../actions";
import { useElementContext } from "../../contexts/ElementContext";
import { REVIEW, getCurrentStep } from "./steps";
import { isStripePayment } from "./Payment/StripePayment";
import { orderPayment } from "../../selectors";

const CheckoutSideBar = ({ placeOrder, orderProperties, setOrderProperties }) => {
  const dispatch = useDispatch();
  const {
    CartModule,
    CommonModule: { OrderNotes, Button, CartOrderSummary, StripeSubmitButton },
  } = useElementContext();
  const cart = useSelector((state) => state.cart);
  const payment = useSelector(orderPayment);
  const { isFetching } = cart;
  const loc = useLocation();
  const path = loc.pathname.split("/").reverse()?.at(0).toLowerCase();
  const currentStep = getCurrentStep(path);
  const { t } = useTranslation();

  return (
    <aside className="col-lg-4 pt-4 pt-lg-0">
      <div className=" rounded-lg box-shadow-lg ml-lg-auto">
        <CartOrderSummary />
        {currentStep.key !== REVIEW && (
          <CartModule.CoreComponents.CartPromoBox
            onApplyCode={(promoCode, setPromoCode) => {
              dispatch(applyPromoCode(promoCode, t("frontend.cart.promo_code_applied")));
              setPromoCode("");
            }}
          />
        )}
        {currentStep.key === REVIEW && (
          <>
            <OrderNotes orderProperties={orderProperties} setOrderProperties={setOrderProperties} />
            <div className=" pt-1 mb-2">
              <textarea
                className="form-control UpdatePropertyTextArea"
                rows="2"
                id={`deliveryInstructions-TextArea`}
                disabled={cart.isFetching}
                value={orderProperties.deliveryInstructions}
                placeholder={"Delivery Instructions"}
                onChange={(e) => {
                  e.preventDefault();
                  setOrderProperties({
                    ...orderProperties,
                    OrderDeliveryInstructions: e.target.value,
                  });
                }}
              />
            </div>
            <div className=" pt-1 pb-2">
              <textarea
                className="form-control UpdatePropertyTextArea"
                rows="2"
                id={`customerReferenceNumber-TextArea`}
                disabled={cart.isFetching}
                value={orderProperties.customerReferenceNumber}
                placeholder={"Customer Reference Number"}
                onChange={(e) => {
                  e.preventDefault();
                  setOrderProperties({
                    ...orderProperties,
                    customerReferenceNumber: e.target.value,
                  });
                }}
              />
            </div>
            {isStripePayment(payment?.paymentMethod) ? (
              <StripeSubmitButton />
            ) : (
              <Button
                disabled={isFetching}
                isLoading={isFetching}
                label={t("frontend.order.complete")}
                classList="btn btn-primary btn-lg btn-block w-100 my-2"
                onClick={placeOrder}
              />
            )}
          </>
        )}
      </div>
    </aside>
  );
};

export { CheckoutSideBar };

import { useLocation } from "react-router-dom";
import quertString from "query-string";
import { ProductTypeData } from "../../../modules/Product/Components/ProductTypeList/ProductTypeList.componentModel";
import { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useElementContext } from "../../../contexts";

export interface BrandRangePreFilterProps {
  productTypeData: ProductTypeData;
  setProductTypeSlug: (slug: string) => void;
  rangeMetaData: {
    [key: string]: { rangeName: string; rangeLogo: string };
  };
  rangeTilesSettings: "showOnlyDirectChild" | "showHierarchy";
}

const findCurrentProductType = (
  productTypeData: ProductTypeData,
  productTypeSlug: string,
): ProductTypeData | undefined => {
  let currentProductType = productTypeData?.childProductTypes.find(
    (productType) => productType.urlTitle === productTypeSlug,
  );

  if (currentProductType) return currentProductType;
  for (var productType of productTypeData.childProductTypes) {
    currentProductType = findCurrentProductType(productType, productTypeSlug);
    if (currentProductType) return currentProductType;
  }
  return undefined;
};

export const BrandRangePreFilter = ({
  productTypeData,
  rangeTilesSettings,
  rangeMetaData = {},
  setProductTypeSlug,
}: BrandRangePreFilterProps) => {
  const {
    CommonModule: { SWImage },
  } = useElementContext();
  const location = useLocation();
  const params = quertString.parse(location.search);
  const productTypeSlug = params.product_type_slug as string;
  const currentProductType = useMemo(() => {
    if (!productTypeSlug) return productTypeData;
    return findCurrentProductType(productTypeData, productTypeSlug) || productTypeData;
  }, [productTypeData, productTypeSlug]);

  useEffect(() => {
    if (currentProductType.childProductTypes?.length === 0) {
      setProductTypeSlug(currentProductType.urlTitle);
    } else {
      setProductTypeSlug("");
    }
  }, [currentProductType, setProductTypeSlug]);

  const getProductTypePath = (urlTitle: string) =>
    `${location.pathname}?${quertString.stringify({ ...params, product_type_slug: urlTitle })}`;

  if (!currentProductType.childProductTypes?.length) return null;

  return (
    <div className="container pb-4 pb-sm-5">
      <div className="row">
        {currentProductType.childProductTypes
          ?.sort((a: any, b: any) => (a.productTypeName > b.productTypeName ? 1 : -1))
          .map(({ productTypeID, productTypeName, imageFile, urlTitle, childProductTypes }: any) => {
            let customImagePath = "";
            let imageFileName = "";
            if (imageFile !== "") {
              imageFileName = imageFile.split("/").reverse()?.at(0);
              customImagePath = imageFile.split("/").slice(0, -1).join("/") + "/";
            }

            const rangeData = rangeMetaData[urlTitle];
            productTypeName = rangeData?.rangeName || productTypeName;
            imageFileName = rangeData?.rangeLogo || imageFileName;

            return (
              <div className="col-lg-5-cols col-md-4 col-6 mb-3" key={productTypeID}>
                <div className="card border-0">
                  <Link to={getProductTypePath(urlTitle)} className="d-block overflow-hidden rounded-lg">
                    <SWImage
                      className="d-block w-100"
                      customPath={customImagePath}
                      src={imageFileName}
                      alt={productTypeName}
                      type="productType"
                    />
                  </Link>
                  <div className="card-body">
                    <h2 className="h5">
                      <Link to={getProductTypePath(urlTitle)} className="link-button">
                        {productTypeName}
                      </Link>
                    </h2>

                    {rangeTilesSettings === "showHierarchy" && (
                      <ul className="list-unstyled font-size-sm mb-0">
                        {childProductTypes
                          .sort((a: any, b: any) => (a.productTypeName > b.productTypeName ? 1 : -1))
                          .map(({ productTypeID, productTypeName, urlTitle }: any) => {
                            const rangeData = rangeMetaData[urlTitle];
                            productTypeName = rangeData?.rangeName || productTypeName;
                            return (
                              <li className="d-flex align-items-center justify-content-between" key={productTypeID}>
                                <Link
                                  to={getProductTypePath(urlTitle)}
                                  className="link-button nav-link-style d-flex align-items-center justify-content-between text-left"
                                >
                                  <i className="bi bi-chevron-circle-right pr-2"></i>
                                  {productTypeName}
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useElementContext } from "../../contexts";

export interface LoginRequiredModalProps {
  setShow: (boolean: boolean) => void;
  show: boolean;
  title?: string;
}

export const LoginRequiredModal = ({
  show,
  setShow,
  title,
}: Omit<LoginRequiredModalProps, "type" | "shouldDisplayAuthenticationRequiredMessageFlag">) => {
  const { t } = useTranslation();
  const {
    CommonModule: { Portal, Modal },
  } = useElementContext();
  const location = useLocation();

  const pathname = "/my-account/login";
  const search = `redirect=${location?.pathname}${location?.search}`;

  return (
    <Portal>
      <Modal show={show} setShow={setShow} title={title || t("frontend.account.login")} size="medium">
        <div className="container text-center">
          <div>
            <h4 className="p-0 fw-bold">{t("frontend.logintoperformaction")}</h4>
            <hr />
            <Link className="btn btn-primary" to={`${pathname}?${search}`}>
              {t("frontend.account.login")}
            </Link>
          </div>
        </div>
      </Modal>
    </Portal>
  );
};

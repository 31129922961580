import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmationToken, loadStripe, Stripe, StripeElements } from "@stripe/stripe-js";
import { startCase } from "lodash-es";
import { fulfillmentSelector } from "../../../selectors";
import { EXTERNAL_PAYMENT_CODE, STRIPE_PAYMENT_CODE } from "../../../hooks";
import { Cart, PaymentMethod } from "../../../interface/Cart";
import { useElementContext } from "../../../contexts";
import { addPayment, requestCart } from "../../../actions";
import { axios, getSdkURL, SlatwalApiService } from "../../../services";
import { getErrorMessage } from "../../../utils";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { usePaymentContext } from "../../../contexts/PaymentContext";

export const STRIPE_REDIRECT_PATH = "/checkout/strapi-payment-complete";

export let stripePromise: Promise<Stripe | null> | null = null;

export const isStripePayment = (payment: Cart["orderPayments"][number]["paymentMethod"]) => {
  return (
    payment?.paymentMethodType === EXTERNAL_PAYMENT_CODE &&
    payment?.paymentIntegration?.integrationPackage === STRIPE_PAYMENT_CODE
  );
};

export const useConfirmationToken = ({ orderID }: { orderID: string }) => {
  const [confirmationToken, setConfirmationToken] = useState<ConfirmationToken | undefined>(undefined);
  const [confirmationTokenError, setConfirmationTokenError] = useState("");

  useEffect(() => {
    const fetchToken = async () => {
      if (!orderID) return;
      const token = await axios
        .post(`${getSdkURL()}api/scope/processPaymentTransaction`, {
          orderID,
          transactionType: "getPaymentSummary",
        })
        .then(({ data: { data: { RESULT = null, SUCCESS = false } = {} } = {} }) => {
          if (SUCCESS && RESULT) {
            return RESULT;
          }
          return null;
        })
        .catch(() => null);
      if (token) setConfirmationToken(token);
      else setConfirmationTokenError("Fail to fetch payment data");
    };
    fetchToken();
  }, [orderID]);

  return { confirmationToken, confirmationTokenError };
};

export const useStripe = () => {
  const [stripe, setStripe] = useState<Stripe | null>(null);

  const loadStripeScript = useCallback(() => {
    if (!stripePromise) {
      stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "");
    }
    stripePromise.then((stripe) => {
      setStripe(stripe);
    });
  }, []);

  return {
    loadStripeScript,
    stripe,
  };
};

export const StripePayment = ({ method, isEdit = false }: { method: string; isEdit?: boolean }) => {
  const { t } = useTranslation();
  const [elements, setElements] = useState<StripeElements | undefined>(undefined);
  const [stripeError, setStripeError] = useState("");
  const cart = useSelector((state: any) => state.cart);
  const dispatch = useDispatch();
  const { fulfillmentMethod } = useSelector(fulfillmentSelector);
  const [saveShippingAsBilling, setSaveShippingAsBilling] = useState(false);
  const {
    CommonModule: { Button, PaymentAddressSelector, ListLoader },
  } = useElementContext();
  const {
    stripe: { stripe, loadStripeScript },
  } = usePaymentContext();

  const elementRef = useRef(elements);
  elementRef.current = elements;

  useEffect(() => {
    loadStripeScript();
  }, [loadStripeScript]);

  useEffect(() => {
    if (!stripe) return;
    if (!elementRef.current) {
      const elements = stripe?.elements({
        mode: "payment",
        currency: cart.currencyCode.toLowerCase(),
        amount: cart.total * 100,
        appearance: {
          theme: "stripe",
        },
      });
      setElements(elements);
    }
  }, [cart.total, cart.currencyCode, stripe]);

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current?.update({ amount: cart.total, currency: cart.currencyCode.toLowerCase() });
    }
  }, [cart.total, cart.currencyCode]);

  useEffect(() => {
    if (!elements) return;
    const paymentElement = elements.create("payment", { layout: "tabs" });
    paymentElement.mount("#stripe-payment-element");
    return () => {
      paymentElement.destroy();
    };
  }, [elements]);

  const onAddPayment = async ({ newOrderPayment, ...paymentPayload }: any) => {
    if (!stripe || !elements) return;
    dispatch(requestCart());

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setStripeError(submitError.message as string);
      dispatch(requestCart(false));
      return;
    }

    const { error, confirmationToken } = await stripe?.createConfirmationToken({ elements });

    if (error) {
      if (error?.type === "card_error" || error?.type === "validation_error") {
        setStripeError(error.message as string);
      } else {
        setStripeError("An unexpected error occurred.");
      }
      dispatch(requestCart(false));

      return;
    }

    dispatch(
      addPayment({
        ...paymentPayload,
        newOrderPayment: { ...newOrderPayment, providerToken: confirmationToken.id },
      }) as any,
    );
  };

  return (
    <>
      {elements ? (
        <div id="stripe-payment-element" style={{ minHeight: 300 }} className="py-4" />
      ) : (
        <div className="py-4">
          <ListLoader rowSize={300} row={1} />
        </div>
      )}
      <div>
        <div className="row mb-3">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-6">
                {fulfillmentMethod?.fulfillmentMethodType === "shipping" && (
                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id="saveShippingAsBilling"
                      checked={saveShippingAsBilling}
                      onChange={(e) => {
                        setSaveShippingAsBilling(!saveShippingAsBilling);
                      }}
                    />
                    <label className="custom-control-label ms-1" htmlFor="saveShippingAsBilling">
                      {t("frontend.checkout.shipping_address_clone")}
                    </label>
                  </div>
                )}
              </div>
              <div className="col-12 mt-3">
                {saveShippingAsBilling && (
                  <Button
                    label="Submit"
                    onClick={(e) => {
                      e.preventDefault();
                      onAddPayment({
                        newOrderPayment: { saveShippingAsBilling: 1, paymentMethod: { paymentMethodID: method } },
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {!!stripeError && <div className="col-12 text-dnager">{stripeError}</div>}
        </div>
      </div>

      <div className="row mb-3">
        {!saveShippingAsBilling && (
          <div className="col-sm-12">
            {!saveShippingAsBilling && (
              <PaymentAddressSelector
                isEdit={isEdit}
                addressTitle={t("frontend.account.address.billingAddress")}
                selectedAccountID={cart.orderPayments?.at(0)?.billingAccountAddress?.accountAddressID}
                onSelect={(value: any) => {
                  onAddPayment({
                    newOrderPayment: {
                      accountAddressID: value,
                      paymentMethod: {
                        paymentMethodID: method,
                      },
                    },
                    accountAddressID: value,
                  });
                }}
                onSave={(values: any) => {
                  if (values.saveAddress) {
                    // Create account address
                    // Payment with new Account Address and Single use CC
                    const payload = {
                      name: values.name,
                      streetAddress: values.streetAddress,
                      street2Address: values.street2Address,
                      city: values.city,
                      statecode: values.stateCode,
                      postalcode: values.postalCode,
                      countrycode: values.countryCode,
                      emailAddress: values.emailAddress,
                      phoneNumber: values.phoneNumber,
                      returnJSONObjects: "account",
                    };

                    SlatwalApiService.cart.addEditAccountAndSetAsBillingAddress(payload).then((response: any) => {
                      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length)
                        toast.error(getErrorMessage(response.success().errors));
                      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length === 0) {
                        onAddPayment({
                          newOrderPayment: {
                            paymentMethod: {
                              paymentMethodID: method,
                            },
                          },
                        });
                      }
                    });
                  } else {
                    // and payment with new single use CC and Single use address
                    onAddPayment({
                      newOrderPayment: {
                        billingAddress: {
                          name: values.name,
                          streetAddress: values.streetAddress,
                          street2Address: values.street2Address,
                          city: values.city,
                          statecode: values.stateCode,
                          postalcode: values.postalCode,
                          countrycode: values.countryCode,
                          emailAddress: values.emailAddress,
                          phoneNumber: values.phoneNumber,
                        },
                        paymentMethod: {
                          paymentMethodID: method,
                        },
                      },
                    });
                  }
                }}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export const StripePaymentDetails = ({ payment }: { payment: { paymentMethod: PaymentMethod } }) => {
  const { t } = useTranslation();
  const { orderID } = usePaymentContext();

  const { confirmationToken, confirmationTokenError } = useConfirmationToken({ orderID });
  const {
    CommonModule: { ListLoader },
  } = useElementContext();

  return (
    <div className="mb-1">
      <em>{payment.paymentMethod.paymentMethodName}</em>
      <br />
      {!confirmationTokenError && !confirmationToken && <ListLoader row={1} rowSize={18} />}
      {!!confirmationToken && (
        <div>
          {t("frontend.checkout.payment.type")}: {startCase(confirmationToken?.payment_method_preview.type)}
        </div>
      )}
      {confirmationToken?.payment_method_preview.card && (
        <div>
          {startCase(confirmationToken?.payment_method_preview.card.brand).toUpperCase()}{" "}
          {t("frontend.account.payment_method.ending_in")} {confirmationToken?.payment_method_preview.card.last4}
        </div>
      )}
      {!!confirmationTokenError && <div className="text-danger">{confirmationTokenError}</div>}
    </div>
  );
};

export const StripeSubmitButton = ({
  disabled,
  label = "",
  classList = "btn btn-primary btn-lg btn-block w-100 my-2",
}: {
  disabled?: boolean;
  label?: string;
  classList?: string;
}) => {
  const {
    orderID,
    stripe: { stripe, loadStripeScript },
  } = usePaymentContext();
  const { confirmationToken, confirmationTokenError } = useConfirmationToken({ orderID });
  const {
    CommonModule: { Button },
  } = useElementContext();
  const { t } = useTranslation();

  useEffect(() => {
    loadStripeScript();
  }, [loadStripeScript]);

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !confirmationToken) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const {
      data: { data: { content: { clientSecret, transactionID } = { clientSecret: "", transactionID: "" } } = {} },
    } = await axios.post(`${getSdkURL()}api/scope/processPaymentTransaction`, {
      orderID: orderID,
      transactionType: "createPaymentIntent",
    });

    if (!clientSecret || !transactionID) {
      setMessage("An unexpected error occurred.");
      setIsLoading(false);
      return;
    }

    const { error } = await stripe.confirmPayment({
      clientSecret,
      confirmParams: {
        confirmation_token: confirmationToken.id,
        return_url: `${window.origin}${STRIPE_REDIRECT_PATH}?orderID=${orderID}&transactionID=${transactionID}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message as string);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <>
      <Button
        disabled={disabled || isLoading || !stripe || !confirmationToken}
        isLoading={isLoading}
        label={label || t("frontend.order.complete")}
        classList={`checkout_stripe_checkoutButton ${classList}`}
        onClick={handleSubmit}
      />
      {(message || confirmationTokenError) && <p className="text-danger">{message || confirmationTokenError}</p>}
    </>
  );
};

const ErrorIcon = (
  <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25628 1.25628C1.59799 0.914573 2.15201 0.914573 2.49372 1.25628L8 6.76256L13.5063 1.25628C13.848 0.914573 14.402 0.914573 14.7437 1.25628C15.0854 1.59799 15.0854 2.15201 14.7437 2.49372L9.23744 8L14.7437 13.5063C15.0854 13.848 15.0854 14.402 14.7437 14.7437C14.402 15.0854 13.848 15.0854 13.5063 14.7437L8 9.23744L2.49372 14.7437C2.15201 15.0854 1.59799 15.0854 1.25628 14.7437C0.914573 14.402 0.914573 13.848 1.25628 13.5063L6.76256 8L1.25628 2.49372C0.914573 2.15201 0.914573 1.59799 1.25628 1.25628Z"
      fill="white"
    />
  </svg>
);

const InfoIcon = (
  <svg width="100%" height="100%" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.5H4C2.61929 1.5 1.5 2.61929 1.5 4V10C1.5 11.3807 2.61929 12.5 4 12.5H10C11.3807 12.5 12.5 11.3807 12.5 10V4C12.5 2.61929 11.3807 1.5 10 1.5ZM4 0C1.79086 0 0 1.79086 0 4V10C0 12.2091 1.79086 14 4 14H10C12.2091 14 14 12.2091 14 10V4C14 1.79086 12.2091 0 10 0H4Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 7C5.25 6.58579 5.58579 6.25 6 6.25H7.25C7.66421 6.25 8 6.58579 8 7V10.5C8 10.9142 7.66421 11.25 7.25 11.25C6.83579 11.25 6.5 10.9142 6.5 10.5V7.75H6C5.58579 7.75 5.25 7.41421 5.25 7Z"
      fill="white"
    />
    <path
      d="M5.75 4C5.75 3.31075 6.31075 2.75 7 2.75C7.68925 2.75 8.25 3.31075 8.25 4C8.25 4.68925 7.68925 5.25 7 5.25C6.31075 5.25 5.75 4.68925 5.75 4Z"
      fill="white"
    />
  </svg>
);

export const STATUS_CONTENT_MAP = {
  processing: {
    text: "frontend.checkout.payment.stripe_status.processing",
    iconColor: "#6D6E78",
    icon: InfoIcon,
  },
  requires_payment_method: {
    text: "frontend.checkout.payment.stripe_status.requires_payment_method",
    iconColor: "#DF1B41",
    icon: ErrorIcon,
  },
  default: {
    text: "frontend.checkout.payment.stripe_status.default",
    iconColor: "#DF1B41",
    icon: ErrorIcon,
  },
  canceled: {
    text: "frontend.checkout.payment.stripe_status.canceled",
    iconColor: "#DF1B41",
    icon: ErrorIcon,
  },
  requires_action: {
    text: "frontend.checkout.payment.stripe_status.requires_action",
    iconColor: "#DF1B41",
    icon: ErrorIcon,
  },
  requires_capture: {
    text: "frontend.checkout.payment.stripe_status.requires_capture",
    iconColor: "#DF1B41",
    icon: ErrorIcon,
  },
  requires_confirmation: {
    text: "frontend.checkout.payment.stripe_status.requires_confirmation",
    iconColor: "#DF1B41",
    icon: ErrorIcon,
  },
  place_order_fail: {
    text: "frontend.checkout.payment.stripe_status.place_order_fail",
    iconColor: "#DF1B41",
    icon: ErrorIcon,
  },
} as const;

export const StripeCompletePage = ({ placeOrder }: { placeOrder: () => Promise<boolean> }) => {
  const {
    CommonModule: { Spinner },
  } = useElementContext();
  const { t } = useTranslation();
  const {
    stripe: { stripe, loadStripeScript },
  } = usePaymentContext();

  useEffect(() => {
    loadStripeScript();
  }, [loadStripeScript]);

  const [status, setStatus] = useState<keyof typeof STATUS_CONTENT_MAP | "succeeded" | "">("");
  const [placeOrderError, setPlaceOrderError] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const clientSecret = params.get("payment_intent_client_secret");

    if (!clientSecret) {
      setStatus("default");
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (!paymentIntent) {
        setStatus("default");
        return;
      }

      setStatus(paymentIntent.status);
    });
  }, [stripe]);

  const placeOrderRef = useRef(placeOrder);
  placeOrderRef.current = placeOrder;
  useEffect(() => {
    if (!status) return;

    const handlePlaceOrder = async () => {
      const params = new URLSearchParams(window.location.search);
      const orderID = params.get("orderID");
      const transactionID = params.get("transactionID");

      if (status === "succeeded") {
        await axios.post(`${getSdkURL()}api/scope/processPaymentTransactionStatus`, {
          orderID,
          transactionID,
          successFlag: true,
        });
        const result = await placeOrderRef.current();
        if (!result) {
          setPlaceOrderError(true);
        }
      } else {
        await axios.post(`${getSdkURL()}api/scope/processPaymentTransactionStatus`, {
          orderID,
          transactionID,
          successFlag: false,
        });
      }
    };

    handlePlaceOrder();
  }, [status]);

  const renderErrorStatus = (status: keyof typeof STATUS_CONTENT_MAP) => {
    return (
      <div
        className="checkout_stripe_paymentStatus container d-flex flex-column align-items-center px-2 py-4 gap-3"
        style={{ maxWidth: 600 }}
      >
        <>
          <div
            className="checkout_stripe_paymentStatusIcon"
            style={{
              backgroundColor: STATUS_CONTENT_MAP[status].iconColor,
              width: 80,
              height: 80,
              borderRadius: "50%",
              padding: "20px",
            }}
          >
            <div style={{ width: "100%", height: "100%" }}>{STATUS_CONTENT_MAP[status].icon}</div>
          </div>
          <p className="checkout_stripe_paymentStatusText text-center mb-0">{t(STATUS_CONTENT_MAP[status].text)}</p>
          <Link className="btn btn-primary" to="/shopping-cart">
            Back to Cart
          </Link>
        </>
      </div>
    );
  };

  if (placeOrderError) return renderErrorStatus("place_order_fail");

  if (!status || status === "succeeded") {
    return <Spinner />;
  }

  return renderErrorStatus(status);
};

import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useListing } from "../../../../hooks";
import { SearchListingProps, SearchListingModel } from "./SearchListing.componentModel";
import { LISTING } from "../../productListing.moduleModels";
import { useListingTopBar } from "../../Controls/ListingTopbar";

export const useComponentData = (props: SearchListingProps) => {
  const defaultListingConfig = useSelector((state: any) => state.configuration.defaultListingConfig);
  const [preFilter] = useState(props.initPreFilter);
  const searchConfig = useMemo(
    () => JSON.parse(props?.listingConfig) || defaultListingConfig,
    [defaultListingConfig, props?.listingConfig],
  );
  const searchListingData = useListing(preFilter, searchConfig);
  const [viewMode, setViewMode] = useState(searchConfig.viewMode || LISTING);

  const listingTopbar = useListingTopBar({
    searchConfig,
    filtering: searchListingData.potentialFilters,
    updateAttribute: searchListingData.updateAttribute,
  });

  const componentData = useMemo(
    () => new SearchListingModel({ props, searchConfig, searchListingData, viewMode, listingTopbar }),
    [props, searchConfig, searchListingData, viewMode, listingTopbar],
  );

  return {
    componentData,
    componentService: {
      setViewMode,
    },
  };
};

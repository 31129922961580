import { useTranslation } from "react-i18next";
import { useProductContext } from "../../contexts/ProductContext";
import { useElementContext } from "../../contexts";

const ProductReview = () => {
  const { ratingData } = useProductContext();
  const { records, isFetching, total, totalPages, setCurrentPage, currentPage, ratingCount, averageRating } =
    ratingData;
  const {
    CommonModule: { ListingPagination, ProductReviewListing, ProductReviewRating },
  } = useElementContext();

  const { t } = useTranslation();
  const average = isNaN(averageRating) ? "0" : Math.round(averageRating);
  return (
    <section className="bg-light my-4">
      <div className="container">
        <header className="section-title">
          <h2>{t("frontend.product.review.mainHeading")}</h2>
        </header>
        <div className="mb-5">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                {records.length > 0 && (
                  <div className="bg-transparent mb-2">
                    <div className="d-flex align-items-baseline justify-content-between">
                      <div className="star-rating d-flex align-items-baseline">
                        <ProductReviewRating total={average} />
                        <p className="text-black ml-2 p-2">
                          {average + " " + t("frontend.product.review.chart.totalReviewText")}
                        </p>
                      </div>
                      <p className="total-reviews">
                        {total} {t("frontend.product.review.chart.reviewText")}
                      </p>
                    </div>

                    <div className="graph-star-rating-body">
                      {ratingCount.map((review, index) => {
                        const ratePer = total > 0 ? Math.round((review.ratingCount / total) * 100) : "0";
                        return (
                          <div className="d-flex align-items-center" key={index}>
                            <div className="rating-list-left text-black">
                              {review.rating + " " + t("frontend.product.review.chart.starTitle")}
                            </div>
                            <div className="rating-list-center p-3 flex-grow-1">
                              <div className="progress">
                                <div
                                  style={{ width: ratePer + "%" }}
                                  aria-valuemax="5"
                                  aria-valuemin="0"
                                  aria-valuenow="5"
                                  role="progressbar"
                                  className="progress-bar bg-primary"
                                >
                                  <span className="sr-only opacity-0"></span>
                                </div>
                              </div>
                            </div>
                            <div className="rating-list-right text-black">{ratePer} %</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <ProductReviewListing isFetching={isFetching} pageRecords={records} />
                <ListingPagination
                  recordsCount={total}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setPage={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export { ProductReview };

import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes as RouterRoutes, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { clearUser, receiveUser, requestLogOut, receiveCart, requestCart } from "../../actions";
import { getErrorMessage, isAuthenticated } from "../../utils";
import { SlatwalApiService } from "../../services";
import "./checkout.css";
import { useElementContext } from "../../contexts/ElementContext";
import { SHIPPING, getCurrentStep } from "../../components/Checkout/steps";
import { useServiceContext } from "../../contexts";
import { STRIPE_REDIRECT_PATH } from "../../components/Checkout/Payment/StripePayment";
import { PaymentContextProvider } from "../../contexts/PaymentContext";

const Routes = Sentry.withSentryReactRouterV6Routing(RouterRoutes);

const Checkout = () => {
  const {
    PageModule: { DynamicPage },
    CommonModule: {
      AccountLogin,
      StepsHeader,
      ShippingSlide,
      PaymentSlide,
      ReviewSlide,
      CreateGuestAccount,
      CheckoutSideBar,
      ThreeDSRedirect,
      RedirectWithReplace,
      StripeCompletePage,
      Spinner,
    },
  } = useElementContext();
  const { LogService } = useServiceContext();
  const logService = useMemo(() => new LogService(), [LogService]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const path = pathname.split("/").reverse()?.at(0).toLowerCase();

  const currentStep = getCurrentStep(path);

  const {
    verifiedAccountFlag,
    isFetching,
    accountID,
    calculatedGuestAccountFlag = false,
  } = useSelector((state) => state.userReducer);
  const enforceVerifiedAccountFlag = useSelector((state) => state.configuration.enforceVerifiedAccountFlag);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cartState = useSelector((state) => state.cart); // check if there is some change in state , just to run use effect
  const [threeDSRedirect, setThreeDSRedirect] = useState();
  const [orderProperties, setOrderProperties] = useState({
    OrderDeliveryInstructions: cartState.deliveryInstructions,
    customerReferenceNumber: cartState.customerReferenceNumber,
    orderNotes: cartState.orderNotes,
  });
  useEffect(() => {
    if (currentStep.key === SHIPPING && !!cartState.orderID) {
      logService.beginCheckout(cartState.orderItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, logService, cartState.orderID]);

  const placeOrder = (event) => {
    event?.preventDefault();
    dispatch(requestCart());
    // Store the hash for guest Checkout
    const payload = !!calculatedGuestAccountFlag ? "cart,account" : "cart";
    const { orderNotes, OrderDeliveryInstructions, customerReferenceNumber } = orderProperties;
    return SlatwalApiService.cart
      .placeOrder({
        returnJSONObjects: payload,
        transactionInitiator: "ACCOUNT",
        orderNotes,
        OrderDeliveryInstructions,
        customerReferenceNumber,
      })
      .then((response) => {
        if (response.isSuccess()) {
          const placeOrderResp = response.success();
          const orderHasError = Object.keys(placeOrderResp?.errors || {})?.length > 0;
          if (placeOrderResp?.redirectUrl) {
            const { redirectUrl, redirectPayload, redirectMethod } = placeOrderResp;
            setThreeDSRedirect({
              redirectUrl,
              redirectPayload,
              redirectMethod,
            });
            return true;
          } else {
            if (orderHasError) {
              toast.error(getErrorMessage(response.success().errors));
              return false;
            } else {
              dispatch(receiveCart(placeOrderResp.cart));
              dispatch(receiveUser(placeOrderResp.account));
              // TODO: verify isGuest
              if (!!calculatedGuestAccountFlag) {
                navigate(`/my-account/order-detail?token=${cartState.orderID}:${accountID}`);
              } else {
                navigate("/order-confirmation");
              }
              logService.purchase(cartState);
              return true;
            }
          }
        } else {
          toast.error(t("frontend.core.error.network"));
          dispatch(receiveCart());
          return false;
        }
      })
      .catch((err) => {
        toast.error(t("frontend.core.error.network"));
        dispatch(receiveCart());
        return false;
      });
  };
  useEffect(() => {
    if (!isAuthenticated()) {
      dispatch(clearUser());
      dispatch(requestLogOut());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (enforceVerifiedAccountFlag && !verifiedAccountFlag && isAuthenticated() && !isFetching && accountID.length > 0)
    return <Navigate to="/account-verification" />;

  if (!cartState || (cartState.isFetching && !cartState.orderID))
    return (
      <DynamicPage>
        <Spinner />
      </DynamicPage>
    );

  if (pathname === STRIPE_REDIRECT_PATH) {
    return (
      <DynamicPage>
        <PaymentContextProvider orderID={cartState.orderID}>
          <StripeCompletePage placeOrder={placeOrder} />
        </PaymentContextProvider>
      </DynamicPage>
    );
  }

  return (
    <DynamicPage ignoreLayout={true}>
      <div className="checkout container pb-5 mb-2 mb-md-4 pt-4">
        {!isAuthenticated() && (
          <div className="row">
            <section className="col">
              {/* <!-- Steps--> */}
              <Routes>
                <Route path={`createGuestAccount`} element={<CreateGuestAccount />} />
                <Route path={`cart`} element={<RedirectWithReplace pathname={`../../shopping-cart`} />} />
                <Route path={`*`} element={<AccountLogin isCheckout={true} />} />
              </Routes>
            </section>
            {/* <!-- Sidebar--> */}
          </div>
        )}
        {isAuthenticated() && (
          <PaymentContextProvider orderID={cartState.orderID}>
            <div className="row">
              <section className="col-lg-8">
                {/* <!-- Steps--> */}
                <StepsHeader />
                <Routes>
                  <Route path={`cart`} element={<RedirectWithReplace pathname={`../../shopping-cart`} />} />
                  <Route path={`shipping`} element={<ShippingSlide currentStep={currentStep} />} />
                  <Route path={`payment`} element={<PaymentSlide currentStep={currentStep} cartState={cartState} />} />
                  <Route path={`review`} element={<ReviewSlide currentStep={currentStep} />} />
                  <Route path={`*`} element={<RedirectWithReplace pathname={`/checkout/shipping`} />} />
                </Routes>
              </section>
              {/* <!-- Sidebar--> */}
              <CheckoutSideBar
                placeOrder={placeOrder}
                orderProperties={orderProperties}
                setOrderProperties={setOrderProperties}
              />
              {threeDSRedirect && (
                <ThreeDSRedirect
                  url={threeDSRedirect.redirectUrl}
                  payload={threeDSRedirect.redirectPayload}
                  method={threeDSRedirect.redirectMethod}
                />
              )}
            </div>
          </PaymentContextProvider>
        )}
      </div>
    </DynamicPage>
  );
};

export default Checkout;

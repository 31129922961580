import { Stripe } from "@stripe/stripe-js";
import { createContext, PropsWithChildren, useContext } from "react";
import { useStripe } from "../components/Checkout/Payment/StripePayment";

export const PaymentContext = createContext<{
  orderID: string;
  stripe: {
    loadStripeScript: () => void;

    stripe: Stripe | null;
  };
}>({
  orderID: "",
  stripe: {
    loadStripeScript: () => {},

    stripe: null,
  },
});

export const PaymentContextProvider = ({ orderID, children }: PropsWithChildren<{ orderID: string }>) => {
  const stripe = useStripe();
  return <PaymentContext.Provider value={{ orderID, stripe }}>{children}</PaymentContext.Provider>;
};

export const usePaymentContext = () => {
  return useContext(PaymentContext);
};

import { OrderItem_SkuConfiguration } from "../../../interface/Cart";

export interface OrderItemSkuConfigurationsProps {
  skuConfigurations: OrderItem_SkuConfiguration[];
}

export const OrderItemSkuConfigurations = ({ skuConfigurations }: OrderItemSkuConfigurationsProps) => {
  if (!skuConfigurations?.length) return null;
  return (
    <details className="font-size-sm item-sku-configurations">
      <summary className="small">Configuration</summary>
      <div>
        {skuConfigurations.map(({ groupCode, groupName, name }) => {
          return (
            <div key={groupCode}>
              <small>
                {groupName}: {name}
              </small>
            </div>
          );
        })}
      </div>
    </details>
  );
};

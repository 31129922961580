import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { Product } from "../../../../interface/Product";
import { RelatedProductsSliderModel } from "./RelatedProductsSlider.componentModel";
import { useElementContext } from "../../../../contexts/ElementContext";
import { SearchSku } from "../../../../interface/SearchProduct";

const RelatedProductsSliderView = ({ componentData }: { componentData: RelatedProductsSliderModel }) => {
  const { t } = useTranslation();
  const { ProductCardModule } = useElementContext();
  const { slidesToShow, products, isLoaded } = componentData;

  if (!products.length) {
    return null;
  }
  const settings = {
    dots: false,
    infinite: products && products.length >= slidesToShow,
    // infinite: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Math.min(3, slidesToShow),
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: Math.min(2, slidesToShow),
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(1, slidesToShow),
        },
      },
    ],
  };
  return (
    <section>
      <div className="container">
        <header className="section-title pb-2">
          <h2 className="mb-4">{t("frontend.product.related")}</h2>
        </header>
        <div className="card border-0 bg-transparent">
          <Slider {...settings}>
            {isLoaded &&
              products.map((product: Product) => {
                //@ts-ignore
                product.skuName = product?.relatedProduct_productName;
                //@ts-ignore
                product.product_brand_brandName = product?.brandName;
                //@ts-ignore
                product.product_urlTitle = product?.relatedProduct_urlTitle;
                //@ts-ignore
                product.product_brand_urlTitle = product?.brandUrlTitle;
                return (
                  <div className="repeater" key={product.productCode}>
                    <ProductCardModule.CoreControl.SkuCard
                      cardConfiguration={{
                        input: {
                          showCriteria: "none",
                          label: "",
                        },
                        showPrice: true,
                        showBrand: true,
                        showSkuCode: true,
                        showProductCode: true,
                        buttons: [
                          {
                            listingButtonLabel: "View More",
                            listingButtonDisplayCriteria: "all",
                            disableListingButton: false,
                            showAuthenticationRequiredMessageFlag: false,
                            hideListingButton: false,
                            type: "VIEW",
                          },
                        ],
                      }}
                      {...(product as unknown as SearchSku)}
                    />
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
    </section>
  );
};
export { RelatedProductsSliderView };
